import { React, useEffect ,useState} from 'react';
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import Header from './Header';
import { useNavigate  } from "react-router-dom";


function Confirm(props) {

    const [message, setMessage] = useState("");

    const confirmEndPoint = process.env.REACT_APP_CONFIRM_END_POINT;

    const { token = 0 } = useParams();

    const openInSameTab = url => {
        window.open(url, '_self', 'noopener,noreferrer');
    };

    async function getConfirmation(token){ 
        try {
            await fetch(confirmEndPoint+ "/" + token)
                .then(response => response.json())
                .then(function (data) {
                    console.log(data);
                    setMessage(data.message);
                    if (data.token) { 
                        sessionStorage.setItem("userinfo", data.token);
                        console.log(data, token);
                    }
                    setTimeout(function () { 
                        openInSameTab("/");
                    }, 2000);
                });
            
        } catch (e) { 
            console.log("erro");
            console.log(e);
        }
    }

    useEffect(() => {

        if (token) {
            getConfirmation(token)
        } else { 
            openInSameTab("/");
        }
        }, []);





        const SubmitLogin = async () => {
            /*
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: login.current.value,password: password.current.value })
                };
                await fetch(loginEndPoint, requestOptions)
                    .then(response => response.json())
                    .then(function (data) {
                        console.log(data);
                        if (!data.token){
                            setMessage("Wrong Username or Password");
                         } else { 
                            setMessage("Login ok!");
                            sessionStorage.setItem("userinfo", data.token);
                            setTimeout(() => {
                                //back from where it came !
                            }, 2000);
                        }

                    } );
                
            } catch (e) { 
                console.log("erro");
                console.log(e);
            }   
            */


    }

    return (
        <div className={'MainW'}>
            <Header bg={true} destination="/" from="about" />
            <div className="loginMessage" >
                {message}
            </div>
        </div>
        )
}

export default Confirm

