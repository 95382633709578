import * as THREE from 'three'
import { useRef, useState, useEffect,useLocalStorage,useSessionStorage } from 'react'
import { useParams,useNavigate, Link } from 'react-router-dom'
import { Canvas, useFrame } from '@react-three/fiber'
import { CameraControls, Html , Stats, Sky, Stars} from '@react-three/drei'
import { isMobile } from 'react-device-detect';
import { useThree } from "@react-three/fiber"
import PageVisibility from 'react-page-visibility';
import useEventListener from '@use-it/event-listener'

import Header from './Header';

//import { Perf } from 'r3f-perf'
//import data from './data.json'

import {
    TwitterShareButton,
    LinkedinShareButton,
    RedditShareButton,
    WhatsappShareButton,
} from "react-share";


/*
import buybutton from './imgs/buy.png';
import backbutton from './imgs/back.png'
import newbutton from './imgs/new.png';
import moreInfo from './imgs/moreInfo.png';
import lessInfo from './imgs/lessInfo.png';
import buy2 from './imgs/buy2.png';
import cancel from './imgs/cancel.png';
*/
//import { DateRange } from '@mui/icons-material'

/*
FIXED: Flickering far away
*/



const requestEndpoint = process.env.REACT_APP_REQUEST_END_POINT
const paymentGateway = process.env.REACT_APP_PAYMENTGATEWAYNEW
const LockEndPoint = process.env.REACT_APP_LOCK_END_POINT

var speed = 0.1;
var boxes = [];
var next = 10;

var fetchThat;

var randomPosX = (min = -40, max = 40) => Math.random() * (max - min) + min
var randomPosY = (min = -40, max = 40) => Math.random() * (max - min) + min
var randomPosZ = (min = -10, max = 10) => Math.random() * (max - min) + min
var randomPosNew = (min = 0, max = 10) => Math.random() * (max - min) + min


const getcss = (css, mobile) => {
    if (isMobile) {
        css = css + " " + mobile;
    }
    return css;
}



const TextMe3d = (props) => {


    function turnToPhrase(str) {
        var a = "";
        try {
            a = str.replace(/_/g, " ").replace(/\|/g, " ");
        } catch (e) {
            a = str;
        }
        return a;
    }
    var divId = "clickme_" + props.Strawberry;

    return (
        <Html {...props}
            name={divId}
            transform
            position={[props.position[0] - 1, props.position[1], props.position[2] + 1]}
            style={{ pointerEvents: "none", color: 'blue', fontSize: "14px", width: "200px", fontFamily: 'NewFont' }}
        >
            <div  onClick={(e) => { props.handleClick(props.shape.current.position) }}>
                {turnToPhrase(props.text)}
            </div>
        </Html>
    )

}


const CustomBox = (props) => {

    const navigate = useNavigate();

    const shape = useRef();
    const [hidden, setHidden] = useState(true);
    const [isMove, setIsMove] = useState(props.move)
    const [info, SetInfo] = useState(false)
    const [feedback, setFeedback] = useState("");
    
    useEffect(() => {
        setIsMove(props.move)
    }, [props.move])

    const handleClick = (position) => {
        var pos = new THREE.Vector3();
        var look = new THREE.Vector3();

        if (isMove) {
            next = position.z;
            var sum = 2;
            pos.set(position.x, position.y, position.z + sum);
            look.set(position.x, position.y, position.z);
            props.cameraControlRef.current?.moveTo(pos.x, pos.y, pos.z, true);
            props.setTarget(pos);
            setHidden(false);
            props.setMove(false);
            props.setFooter(true);
            navigate('/' + props.Strawberry , { replace: true })
        }
        props.setHello(false);
    }
 


    function close() {
            //console.log(fetchThat);
            clearTimeout(fetchThat);
            props.setMove(true);
            props.setTarget();
            setHidden(true);
            props.setFooter(false);
            navigate('/')
    }

    async function buy(id) {  
        navigate("/buy/" + id);    
    }

    
    const openInSameTab = url => {
        window.open(url, '_self', 'noopener,noreferrer');
    };


    function clickNew() {
            openInSameTab(process.env.PUBLIC_URL + "/new");  
    }

    function logout() { 
        props.setUserInfo();
        sessionStorage.removeItem("userinfo");
        sessionStorage.removeItem("wallet");
    }

    function clicMoreInfo() { 
        var cv = document.getElementById('canvasDiv');
        var more = document.getElementById('MoreInfo');
    
        cv.hidden = true;
        more.hidden = false;

        //SetWant2buy(false);
        //navigate('/about');
    }

    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var created = new Date(Date.parse(props.createdAt));
    var createdString = months[created.getMonth()] + " " + created.getDate() + ", " + created.getFullYear();

    var value = (props.value / 100) * 2;

    var url = process.env.REACT_APP_PUBLIC_URL + "/" + props.id;

 
    return (

        <group>
            <mesh
                ref={shape}
                position={[props.position[0] - 1, props.position[1], props.position[2]]}
            // onClick={(e) => { handleClick(e) }}
            >
                <boxGeometry args={[5, 5, 0.5]} />
                <meshBasicMaterial visible={false}
                    color={'#90EE90'}
                    wireframe
                />
            </mesh>
            <TextMe3d
                position={[props.position[0], props.position[1], props.position[2]]}
                text={props.idea}
                Strawberry={props.Strawberry}
                shape={shape}
                handleClick={handleClick}
            />
            { /* 
            <KeyboardObject  {...props} transparent={true}> </KeyboardObject>
           */}
            <Html
                position={[props.position[0] - 1, props.position[1], props.position[2]]}
                style={{
                    transition: 'all 0.05s',
                    opacity: hidden ? 0 : 1,
                    transform: `scale(${hidden ? 0 : 1})`
                }} center>
              
              <div className={"mouse backToGalaxy"} onClick={(e) => (close())} style={{ display: info ? "none" : "block" }} > [ BACK TO GALAXY ] </div> 
      
                
                <div className="overlay">
                </div>
                
              
                <div className={getcss("ExplainInfoIdea", "ExplainInfoIdea_mobile")} >
                    <div className="buttonsAction">
                        {props.ForSale === 1 ?
                            <div className='mouse' onClick={(e) => buy(props.Strawberry)}>[Buy This Idea]</div>
                            :
                            <div className='mouse' >[ Not for sale ]</div>
                        }
                        <div className='mouse' onClick={(e) => clickNew(e)}>[Create new Idea]</div>
                        <div className='feedbackLayer'>
                            { feedback }
                        </div>
                    </div>
                    <div className="buttonsShare">    
                        <div className='mouse' onClick={() => clicMoreInfo()}>[More info]</div>
                        <br />Share<br />
                        <TwitterShareButton url={url} ><div className='mouseX'>[X]</div></TwitterShareButton>
                        <LinkedinShareButton url={url} ><div className='mousefb'>[Linkedin]</div></LinkedinShareButton>
                        <WhatsappShareButton url={url} ><div className='mouseWs'>[WhatsApp]</div></WhatsappShareButton>
                    </div>
                    <div className="buttonsLogin">
                        {props.userInfo?.token ? 
                            <> 
                            <div className='mouse' onClick={(e) => openInSameTab('/profile')}>[Profile]</div>
                            <div className='mouse' onClick={(e) => logout()}>[Logout]</div>
                            <div className='mouse' onClick={(e) => openInSameTab("/listing")}>[Listing]</div>
                          
                            </>        
                            :
                         <>       
                            <div className='mouse' onClick={(e) => openInSameTab('/login')}>[Login]</div>
                            <div className='mouse' onClick={(e) => openInSameTab("/register")}>[Register]</div>
                            <div className='mouse' onClick={(e) => openInSameTab("/listing")}>[Listing]</div>
                                
                        </>
                        }
                          
                    </div>
                  
                  
                    <form id="buyform" action={paymentGateway} method="POST">
                        <input type="Hidden" name="price" value={value*100} readOnly />
                        <input type="Hidden" name="HWallet" value={window.localStorage.getItem("wallet") === process.env.REACT_APP_OWNWALLET ? true : false } readOnly /> 
                    </form>

                </div>
            </Html>

        </group >
    )
}
const FetchData = ({ setData, setHello, setAbout, data }) => {
    const getIdeas = async () => {
    

        try {
            const response = await fetch(requestEndpoint);
            const data = await response.json();
                        
            const newJson = data.map(obj => {
                    obj.x = randomPosX();
                    obj.y = randomPosY();
                    obj.z = randomPosZ();
                obj.color = new THREE.Color('rgb(255,0,0)');
                return obj;
            });
            sessionStorage.setItem("data", JSON.stringify(newJson));
            console.log("Not from cache")
            setData(newJson);
           
        } catch (error) {
            console.error(error);
        }
        
    };


    useEffect(() => {
        try {
            var d = JSON.parse(sessionStorage.getItem("data"));
            if (d == null) {
                getIdeas();
                setTimeout(() => {
                    setHello(true);
                }, 7000);
            } else { 
                //we need to think about this ( cache or not cache )
                getIdeas();

                //cache system
                //console.log("from cache")
                //setData(d);
            }    
        } catch (error) {
            console.log(error);
        }
    
    });

}



const BoxCreator = ({ data, cameraControlRef, move, setMove, setTarget,finder_id,setFooter,setHello,setAbout,userInfo,setUserInfo }) => {
    const [lastZ, setLastZ] = useState(0);


    if (lastZ === 0) {

        for (let i = 0; i < data.length; i++) {
            data[i].z = i * randomPosZ() * -0.5;
        }
        setLastZ(data[data.length - 1].z)
    }
    boxes = [];

    for (let i = 0; i < data.length; i++) {
        var name = "cube" + i;
        boxes.push({
            name: name,
            object: data[i],
            Thiskey: i,
        })
    }

    var newZ;
    useFrame((state) => {

        newZ = lastZ;

        var index = -1;

        for (var i = 0; i < data.length; i++) {
            if (data[i].z > state.camera.position.z) {
                newZ = lastZ - randomPosNew();
                setLastZ(newZ);
                index = i;
            }
        }


        if (index > -1) {
            data[index].z = newZ;
        }
       
    });
    return (
        <>
            {
                boxes.map((box, index) => (
                    <CustomBox
                        Strawberry={box.object.id}
                        name={box.name}
                        Thiskey={box.Thiskey}
                        key={index}
                        position={[box.object.x, box.object.y, box.object.z]}
                        material-color={box.object.color}
                        idea={box.object.phrase}
                        wallet={box.object.wallet}
                        username={box.object.username}
                        owner={box.object.owner}
                        createdAt={box.object.createdAt}
                        value={box.object.value}
                        ForSale={box.object.ForSale}
                        cameraControlRef={cameraControlRef}
                        move={move}
                        setMove={setMove}
                        setTarget={setTarget}
                        finder_id={finder_id}
                        setFooter={setFooter}
                        setHello={setHello}
                        setAbout={setAbout}
                        setUserInfo={setUserInfo}
                        userInfo={userInfo}
                        
                    />
                ))
            }
        </>
    )
}


const BoxManager = (props) => {
    const {
        data,
        setData,
        cameraControlRef,
        move,
        setMove,
        setTarget,
        filter,
        finder_id,
        setFooter,
        setHello,
        setAbout,
        userInfo,
        setUserInfo
    } = props;

    /*

    var data = [{ orderNumber: 1010, area: "JAPAN", category: "orange", orderApprover: "John Smith" }, 
                { orderNumber: 1020, area: "EM-JAPAN", category: "red", orderApprover: "Paul Smith" }, 
                { orderNumber: 1013, area: "EMEA", category: "orange", orderApprover: "Mike Smith" }, 
                { orderNumber: 1140, area: "APAC", category: "yellow", orderApprover: "Jake Smith" }],
    filters = [
        o => o.orderNumber.toString().includes('10'),
        o => o.area.includes('EM')
    ],
    result = data.filter(o => filters.every(fn => fn(o)));

    console.log(result);
    */

    var filters = [
        //o => o.username.toUpperCase().indexOf(filter.toUpperCase()) > -1,
        o => o.phrase.toUpperCase().indexOf(filter.toUpperCase()) > -1,
    ];
    var data_filtered = data.filter(o => filters.some(fn => fn(o)));
    
    /**
     * fix the single one filter
     */
    if (data_filtered.length === 1) { 
        //console.log(data_filtered);
        //console.log(filter);
        //props.setMove(false);
        //props.setTarget(data_filtered[0])
    }
    //console.log(data_filtered);


    return (
        <>
            {data.length === 0 ?
                <FetchData
                    setData={setData}
                    setHello={setHello}
                    data={data}
                />
                :
                <BoxCreator //todo testing
                    data={data_filtered}
                    cameraControlRef={cameraControlRef}
                    move={move}
                    setMove={setMove}
                    setTarget={setTarget}
                    finder_id={finder_id}
                    setFooter={setFooter}
                    setHello={setHello}
                    setAbout={setAbout}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                />
            }

        </>
    );

}

const CameraGo = ({ cameraControlRef, move, target, setMove }) => {

    useEffect(() => {
        if (cameraControlRef.current) {

            cameraControlRef.current.maxPolarAngle = Math.PI / 2 + 0.17;
            cameraControlRef.current.minPolarAngle = Math.PI / 2 - 0.17;
            cameraControlRef.current.maxAzimuthAngle = 0 + 0.17;
            cameraControlRef.current.minAzimuthAngle = 0 - 0.17;

            cameraControlRef.current.noPan = true;
            cameraControlRef.current.maxDistance = cameraControlRef.current.minDistance = cameraControlRef.current.distance;
            cameraControlRef.current.noKeys = true;
            cameraControlRef.current.noRotate = true;
            cameraControlRef.current.noZoom = true;
        }
    }, [cameraControlRef])

    useFrame((state, delta) => {
        if (move) {
            cameraControlRef.current?.moveTo(0, 0, next, true);
            next -= speed;
        } else {
            if (target) {
                cameraControlRef.current?.moveTo(target.x, target.y, target.z-8, true);
            }
        }

        
    })


    

 


    return (
        <CameraControls ref={cameraControlRef} />
    );
}

const openInSameTab = url => {
    window.open(url, '_self', 'noopener,noreferrer');
};


function clickNew(whereTo) { 
    openInSameTab(process.env.PUBLIC_URL + whereTo );
}


/*      
DONE
    Mint/inventory of ideas [ instead of search ]/
    HyperUranium ( remove the of ideas )
    Terms and Conditions


function GalaxyFooter(props) { 
    return (
        <div className="menuinfo" style={{opacity: props.hide ? 0 : 1,}}>
            <span className={"mouse createone footerClicker"} onMouseDown={() => clickNew("/new")} >Mint /&nbsp;</span>
            <span className="mouse createone footerClicker" onMouseDown={() =>clickNew("/Listing") }>Inventory /&nbsp;</span>
            <span className="mouse createone footerClicker" onMouseDown={() => { }}>Terms and Conditions</span>
            
            { <span className="mouse colorWallet" onMouseDown={() => { ConnectToWallet(props.setWallet) }}>{props.wallet}</span> }
        </ div>
    )
}

*/


const Body = (props) => {
    
    const cameraControlRef = useRef([CameraControls]);
    const [data, setData] = useState([]);
    const [move, setMove] = useState(true)
    const [target, setTarget] = useState();
    const [filter, setFilter] = useState("");
    const [footer, setFooter] = useState(false);
    const [SStats, seStats] = useState(false);
    const [inclination, setInc] = useState(1.0);
    const [azimuth, setAzimuth] = useState(0);
    const [hello, setHello] = useState(false);
    const [about, setAbout] = useState(false);

    var userdata = "";

    if(JSON.parse(sessionStorage.getItem("userinfo"))) {
      
        userdata = JSON.parse(sessionStorage.getItem("userinfo"));
    } else { 
        userdata = "{id:0 , token:''}";
    }
    const [userInfo, setUserInfo] = useState({ id: userdata.id ? userdata.id : 0, token: userdata.token ? userdata.token : "" });
    
 
    const { FilteredMessageid } = useParams()
    
    function FindPositionFromId({id}) { 
        const { scene } = useThree();
        if (id != null) {   
            fetchThat = setTimeout(function () {
                var name = "clickme_" + id;
                var target = scene.getObjectByName(name);
                target.handleClick(target.position);      
            }, 2000);
        }
    }

    
  
    

    const handlerD = (event) => { 
        if (event.key === "ArrowDown") {
            if (inclination > 0) setInc(inclination - 0.01);
        }
        if (event.key === "ArrowUp") {
            if (inclination < 1) setInc(inclination + 0.01);
        } 
        if (event.key === "ArrowLeft") {
            if (azimuth > 0) setAzimuth(azimuth -0.1);
        }
        if (event.key === "ArrowRight") {
            if (azimuth < 1) setAzimuth(azimuth + 0.1);
        }
    }
  
 
   
    const handlerU = (event) => { 
        if (event.key === "s") {
            seStats(!SStats);
        }
    }
    useEventListener('keydown', handlerD);
    useEventListener('keyup', handlerU);
    
    function Messages() {
        const [hide, setHide] = useState(false);
    
        if (hello && !FilteredMessageid) { 
            setTimeout(() => {
                setHide(true);    
                    setTimeout(() => {
                        setHello(false);
                    }, 4500);
            }, 2000);
        }
        return (
             <div className={hide ? 'fadeOut' : 'full'} >
                <div > Please click on an Idea </div>
            </div>
        )
    
        
    }



    const About = () => { 
        return (
            <div className="body_class">
            < div className="readMoreb" >
                    Ideas & Thoughts & Feelings & Visions < br />
                    Questions & Beliefs & Clues & Schemes < br />
                    Hypothesis & Purposes & Plans < br />
                    Statements & Words & Messages < br /> <br />
                    They all live now on Hyperuranium.< br />
                    When tokenized, others start taking care of them.< br /> <br />
                    This is the first version of our platform.It’s design is forked by CENSORED.ART(https://censored.art), Hyperuranium’s first embodiment. Soon the newer version is arriving. To know more contact (apply@hyperuranium.io) For now, ideas are minted by invitation only If you have an idea to propose for Minting contact (apply@hyperuranium.io)
                <br /><br />
            </div >     
            </div >
        )
    }

    
        
    function closeMoreInfo() { 
        var cv = document.getElementById('canvasDiv');
        var more = document.getElementById('MoreInfo');
        
        cv.hidden = false;
        more.hidden = true;
    }

    const navigate = useNavigate();

    return (
        <div className={'MainW'}>
            
            { 
            //<GalaxyFooter setFilter={setFilter} hide={!footer}/>                    
            }
            

            <Header bg={true} destination="/about" />
         
            {hello ? 
                <Messages />
                :
                null
            }
            <div className='MoreInfo' hidden id="MoreInfo">
               
                <div className="body_class">
                <div className='mouse2 moreinfo' onClick={() => closeMoreInfo()} >[ BACK ]</div>
                    < div className="readMoreb" >
                            Ideas & Thoughts & Feelings & Visions < br />
                            Questions & Beliefs & Clues & Schemes < br />
                            Hypothesis & Purposes & Plans < br />
                            Statements & Words & Messages & Prompts < br /> <br />
                            They all live now and forever on Hyperuranium.< br />
                            
                            When tokenized, others start taking care of them.< br /> <br />
                            This is the first version of our platform.Its design is forked by CENSORED.ART(https://censored.art),
                            Hyperuranium’s first embodiment. Soon the newer version is arriving.                         <br /><br />
                        { !isMobile ? 
                            <span className="mouse2" onClick={() => navigate('/listing')}>Listing of Ideas</span>
                            :   
                            ""
                        }           

                    </div >
                                
                </div >
                

            </div>
            <div className='canvasDiv' id='canvasDiv'>
            <Canvas
                width="520" height="520"
                linear
                camera={{ position: [0, 5, 15], fov: 60 }}
                frameloop="demand"
                    gl={{ powerPreference: 'high-performance', antialias: false }}>
                     <FindPositionFromId id={FilteredMessageid} />
                <PageVisibility>
                    {
                        isVisible =>  
                         <CameraGo
                        cameraControlRef={cameraControlRef}
                        move={move}
                        target={target}
                        setMove={setMove}
                        />
                    }
                </PageVisibility>
                <BoxManager
                        data={data}
                        setData={setData}
                        filter={filter}
                        cameraControlRef={cameraControlRef}
                        move={move}
                        setMove={setMove}
                        setTarget={setTarget}
                        finder_id={FilteredMessageid}
                        setFooter={setFooter}
                        setHello={setHello}
                        setAbout={setAbout}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                    />
                    {SStats ? 
                    <Stats />
                    :
                    null    
                    }
                     {
                    inclination > 0.25 &&  inclination < .75 ?    
                        <Sky inclination={inclination} azimuth={azimuth} />
                        :
                        null
                }
                    <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade speed={1} />
                    <Html>
                        
                    </Html>
                </Canvas>
            </div>
 
            </div>
    )

}


export default Body
