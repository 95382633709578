import React, { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";


function Vk({ changePhrase, Phrase, setSig, Focus, Sig, setkeys }) {
    const [input, setInput] = useState("");
    const [layout, setLayout] = useState("default");
    const keyboard = useRef();

    const onChange = input => {
        setInput(input);

    };

    var flag = 0;
    var dimension = 0;

    console.log(Phrase);
    
    function handler( key ) {
        var maxSize = 222;
        var lineMax = 25;
        if (dimension > lineMax) flag = 1;

        console.log(key);
        if (key === "{space}") key = "_";
        if (key === "{ent}") {
            key = "|";
            dimension = 0;
            flag = 0;
        }

        if (flag === 1 && key === "_") {
            key = "|";
            changePhrase(Phrase + key.toLowerCase());
            window.localStorage.setItem("phrase", Phrase + key.toLowerCase());
            console.log("pus o enter");
            dimension = 0;
            flag = 0;
        } else {
            if (key.toLowerCase().length === 1 && Phrase.length < maxSize) {
                changePhrase(Phrase + key.toLowerCase());
                window.localStorage.setItem("phrase", Phrase + key.toLowerCase());
                dimension++;
            } else {
                if (key === "{backspace}") {
                    if (Phrase.length < 2) { 
                        changePhrase("");
                        window.localStorage.setItem("phrase", "");
                    } else { 
                        changePhrase(Phrase.substring(0, Phrase.length - 1));
                        window.localStorage.setItem(Phrase.substring(0, Phrase.length - 1));
                    }
                        dimension--;
                    if (dimension < 0) dimension = 0;
                }
            }
        }
        
    }


    const onKeyPress = button => {
        console.log("Button pressed", button);
       if(Focus === 1){
        handler(button)
       }else{
           if (button.length === 1) {
               setSig(Sig + button);
           } else { 
               if (button === "{backspace}") { 
                   setSig(Sig.substring(0, Sig.length - 1));
               }
               if (button === "{space}") { 
                   setSig(Sig + " ");
               }
           }
        
       }
        if (button === "{shift}") handleClose();
        if (button === "{numbers}" || button === "{abc}") handleNumbers();
    };

    function handleClose() { 
        console.log("close keyboard");
        setkeys(false);
    }

    function handleNumbers() {
        let currentLayout = keyboard.current.options.layoutName;
        let numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";

        keyboard.current.setOptions({
            layoutName: numbersToggle
        });
        setLayout(numbersToggle);
    }

/*
qwertyuiop!@#
asdfghjkl$/:
zxcvbnm&?().
1234567890
*/

    var layoutK = {
        default: [
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "{numbers} z x c v b n m {backspace}",
            "{shift} {space} {ent}"
        ],
        numbers: ["1 2 3 4 5", "6 7 8 9 0","! ? ( ) .","@ : & # $ /", "{abc} {backspace}"]
    };
    var displayK = { 
        "{numbers}": "#",
        "{ent}": "return",
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "⌫",
        "{capslock}": "caps lock ⇪",
        "{shift}": "↓",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC"
        
    };

    return (
        <div className="App">
            <Keyboard
                keyboardRef={r => (keyboard.current = r)}
                mergeDisplay={true}
                layoutName={layout}
                onChange={onChange}
                onKeyPress={onKeyPress}
                layout={layoutK}
                display={displayK}
            />
        </div>
    );
}

export default Vk;
