import React from 'react';
import { isMobile } from 'react-device-detect';
import Header from './Header';
import { useNavigate  } from "react-router-dom";


function ReadMore(props) {

    const navigate = useNavigate();

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };     
    function setTrue() { 
        openInNewTab(process.env.PUBLIC_URL + "/listing");
    }

    return (
        <div className={'MainW'}>
            {
                    <div className="body_class">
                    < div className={isMobile ? "readMorebMob" :  "readMoreb"} >
                            Ideas & Thoughts & Feelings & Visions < br />
                            Questions & Beliefs & Clues & Schemes < br />
                            Hypothesis & Purposes & Plans < br />
                            Statements & Words & Messages < br /> <br />
                            They all live now on Hyperuranium.< br />
                            When tokenized, others start taking care of them.< br /> <br />
                            This is the first version of our platform.It’s design is forked by CENSORED.ART(https://censored.art), Hyperuranium’s first embodiment. Soon the newer version is arriving. To know more contact (apply@hyperuranium.io) For now, ideas are minted by invitation only If you have an idea to propose for Minting contact (apply@hyperuranium.io)
                        <br /><br />
                        { !isMobile ? 
                            <span onMouseDown={() => { setTrue() }} className="clickable">Listing of Ideas</span>
                            :   
                            ""
                        }           

                        <br /><br />
                        <span className="mouse2" onMouseDown={() => { navigate(-1) }} onMouseEnter={() => { }} onMouseLeave={() => { }} >[ Back ]</span>
                    </div >
                                
                    </div >
            
            }
            <Header bg={true} destination="/" from="about"/>
        </div>
        )
}

export default ReadMore

