import React, { useMemo, useState, useEffect, useRef } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    Container,
    DialogActions,
    DialogContent,
    DialogTitle,
    Chip
} from '@mui/material';
import { createTheme, ThemeProvider, useTheme,Switch,FormControlLabel } from '@mui/material';
import './BuroCrat.css'
import LoginProcess from './loginProcess';
import Header from './Header';

const AdminRequestEndpoint = process.env.REACT_APP_ADMIN_GETALL;
const RequestEndPoint = process.env.REACT_APP_REQUEST_END_POINT;
const ApprovalEndPoint = process.env.REACT_APP_APPROVE_END_POINT;
const DisApprovalEndPoint = process.env.REACT_APP_DISAPPROVE_END_POINT; 
const DeleteEndPoint = process.env.REACT_APP_DELETE_END_POINT;
const filterEndPoint = process.env.REACT_APP_FILTER_END_POINT
const forsaleEndPoint = process.env.REACT_APP_FORSALE_END_POINT
const priceEndPoint = process.env.REACT_APP_PRICE_END_POINT
const checkAdminEndPoint = process.env.REACT_APP_ISADMIN_END_POINT


const Buro = (props) => {
    const [isLoading, setisLoading] = useState(true);
    const [FData, setFData] = useState([]);
    const [userinfo,setUserinfo] = useState();
    const [filterMine, setFilterMine] = useState(false);
    const [Login, setLogin] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminList, setAdminList] = useState(false);
    

    const backupData = useRef();

    //const EndPoint = isAdmin ? AdminRequestEndpoint : RequestEndPoint;
    var EndPoint;
 
    useEffect(() => {
        //verficar se ele é admin!
        //para por o butao de admin a funcionar!
        var u = JSON.parse(sessionStorage.getItem("userinfo"));

        if (u != null) {

            setUserinfo(u);
        } else { 
            sessionStorage.removeItem("userinfo");
            setUserinfo(false);
        }
    }, []); 



    useEffect(() => {
        setFData([]); 
    }, [adminList])


    function GettingData(props) {
        if (filterMine) {
            EndPoint = filterEndPoint + userinfo.id;
        } else { 
            EndPoint = RequestEndPoint;
        }
        if (adminList) {
            EndPoint = AdminRequestEndpoint;
        }

        useEffect(() => {
            var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
            const dataFetch = async (state) => {
                var h;
                if (adminList) { 
                    h = {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + userinfo.token
                     }
                } else { 
                     h = {
                        'Content-Type': 'application/json',
                    }
                
                }
               
                var options = {
                    method: 'GET',
                    mode: 'cors',
                    headers: h,
                }

                var data = await (
                    await fetch(
                        EndPoint
                        , options)
                ).json();

                if (data.statusCode === 401) { 
                    
                    //logout();

                }


                if (FData.length === 0) {
                    backupData.current = data;
                    //console.log(data);
                    setFData(data);
                    setisLoading(false); 
                }

                
            };

            dataFetch();
        }, [FData]); // eslint-disable-line react-hooks/exhaustive-deps
    }

    //should be memoized or stable

    function turnToPhrase(str) {
        return str.replace(/_/g, " ").replace(/\|/g, " ");
    }

    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    function created(date) { return new Date(Date.parse(date)) };
    function createdString(d) { return months[created(d).getMonth()] + " " + created(d).getDate() + ", " + created(d).getFullYear() };


    const columns = useMemo(
        () => [
       {
                accessorKey: 'author', //normal accessorKey
                header: 'Author',
                size: 50,
                grow: true, //a size: 12,
                enableEditing: false,
                
            },
            {
                accessorKey: 'phrase', //access nested data with dot notation
                header: 'Idea',
                accessorFn: (row) => turnToPhrase(row.phrase),
                enableEditing: false,
                
            },
            {
                accessorKey: 'value', //access nested data with dot notation
                header: 'value',
                accessorFn: (row) => row.value/100,
                
            }
        ],// eslint-disable-next-line
        [], 
    );
    const columnsAdmin = useMemo(
        () => [

            {
                accessorKey: 'phrase', //access nested data with dot notation
                header: 'Idea',
                accessorFn: (row) => turnToPhrase(row.phrase),
                size: 50,
            },
            {
                accessorKey: 'author', //normal accessorKey
                header: 'Author',
                size: 50,
                grow: true, //a size: 12,
                enableEditing: false,
                
            },
            {
                accessorKey: 'createdAt',
                header: 'Creation Date',
                accessorFn: (row) => createdString(row.createdAt),
            },
            {
                accessorKey: 'value',
                header: 'Value',
                size: 5,
                accessorFn: (row) => row.value / 100 + " €",
            },
            {
                accessorKey: 'series',
                header: 'serie',
                size: 5,
                accessorFn: (row) => row.series === 0 ? "[1/1]" : row.series === 1 ? "[1/10]" : row.series === 2 ? "[1/20]" : row.series === 3 ? "[1/200]" : "" , 
            },
            {
                accessorKey: 'aproved',
                header: 'Approved',
                size: 5,
                accessorFn: (row) => row.aproved > -1 ? row.aproved === 0 ? aprovedButton(row) : disaprovedButton(row) : newButton(row), 
                    //row.aproved ? row.aproved > -1 ? disaprovedButton(row) : newButton(row) : aprovedButton(row),
            }
        ],// eslint-disable-next-line
        [],
    );

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const openSameTab = url => {
        window.open(url, "_self", 'noopener,noreferrer');
    };




    async function handleDelete(row) {
            
        var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
            const h = {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + userinfo.token
            }
            var options = {
                method: 'DELETE',
                mode: 'cors',
                headers: h,
            }

            try {
                const response = await fetch(DeleteEndPoint + row.id, options);
            
                if (response.statusCode === 401) {
                    logout();
                    return
                }
                if (response.ok) { 
                const index = backupData.current.map(object => object.id).indexOf(row.id);
                
                setFData(oldArray => (
                    [...oldArray.slice(0, index),
                    ...oldArray.slice(index+1 )])
                )
                
                if (index > -1) { // only splice array when item is found
                    backupData.current.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            
        } catch (error) {
            console.log(error);
        }
       
        }
    function filter() { 
        setFilterMine(!filterMine);
        setFData([]);

    }


    
    
    function aprovedButton(row) { 

        
        async function handleAproval(row) { 
            var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    
                    
            const h = {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + userinfo.token
            }
        
            var options = {
                method: 'GET',
                mode: 'cors',
                headers: h,
            }

            
            const response = await fetch(ApprovalEndPoint + row.id, options);
            const reply = await response.json();
           
            if (reply.statusCode === 401) {
                logout();
                return
            } 
          
            const index = backupData.current.map(object => object.id).indexOf(row.id);
  
            setFData(oldArray => (
                [...oldArray.slice(0, index),
                    reply,
                ...oldArray.slice(index+1)])
            )

            
        }

        return (
            <Button
                color="error"
                onClick={() => {
                    handleAproval(row);
                }}
                variant="contained"
            >
                Approve
            </Button>
        )
    }

    function newButton(row) {
       
       async function handleAproval(row) {
         var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
       
           const h = {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + userinfo.token
            }
            var options = {
                method: 'GET',
                mode: 'cors',
                headers: h,
            }

            const response = await fetch(ApprovalEndPoint + row.id, options);
            const reply = await response.json();

            if (reply.statusCode === 401) {
                logout();
                return
            }

            const index = backupData.current.map(object => object.id).indexOf(row.id);

            setFData(oldArray => (
                [...oldArray.slice(0, index),
                    reply,
                ...oldArray.slice(index + 1)])
            )


        }

        return (
            <Button
                color="primary"
                onClick={() => {
                    handleAproval(row);
                }}
                variant="contained"
            >
               New
            </Button>
        )
    }


    


    function disaprovedButton(row) {
        async function handleDisaproval(row) {
            var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
            const h = {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + userinfo.token
            }
            var options = {
                method: 'GET',
                mode: 'cors',
                headers: h,
            }

            const response = await fetch(DisApprovalEndPoint + row.id, options);
            const reply = await response.json();

            if (reply.statusCode === 401) {
                logout();
                return
             } 
                
            const index = backupData.current.map(object => object.id).indexOf(row.id);
  
            setFData(oldArray => (
                [...oldArray.slice(0, index),
                    reply,
                ...oldArray.slice(index + 1)])
            )


        }
        return (
            <Button
                color="success"
                onClick={() => {
                    handleDisaproval(row);
                }}
                variant="contained"
            >
                Approved
            </Button>
        )
    }


    const globalTheme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const tableTheme = useMemo(
    () =>
      createTheme({
        
      }),
    [globalTheme],
  );
    
    
    function switchthis(rowMe) { 
        var s = <Switch defaultChecked={rowMe.ForSale ? true : false} onChange={(e) => forsale(e,rowMe.id)}  ></Switch>
            
        return s
    }

    async function forsale(t, id) { 
        
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: id,state: t.target.checked  ? 1 : 0})
            };
            await fetch(forsaleEndPoint , requestOptions)
                .then(response => response.json())
                .then(function (data) {
                    
                });


        } catch (e) { 
            console.log("erro");
            console.log(e);
        }
    }

    
    async function handleSaveUser(e) {
        var newPrice = parseFloat(e.values.value)*100;
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: e.row.original.id,newPrice: newPrice })
            };

            await fetch( priceEndPoint , requestOptions)
                .then(response => response.json())
                .then(function (data) {
                    });


        } catch (e) { 
            console.log("erro");
            console.log(e);
        }

     
    };
    
    function logout() { 
        sessionStorage.removeItem("userinfo");
        sessionStorage.removeItem("wallet");
        setUserinfo(false);
        setIsAdmin(false);
        setAdminList(false);
    }

    function afterLogin() { 
        setTimeout(() => {
            var u = JSON.parse(sessionStorage.getItem("userinfo"));
            setUserinfo(u);
            var h = {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + u.token
             }
           var options = {
                method: 'GET',
                mode: 'cors',
                headers: h,
            }
    
            const checkIfAdmin = async () => {
                await fetch(checkAdminEndPoint + u.id,options)
                .then(response => response.json())
                .then(function (data) {
                    setIsAdmin(data.isadmin);
                });
                
            }
            checkIfAdmin()      
            
        }, 2000);
    }

    useEffect(() => { 
        setLogin(false);
        
    },[userinfo]);

    return (
        <div className={'MainW'}>
            {Login ? <div>
                <Header bg={true} destination="/" from="about" />
                <LoginProcess callback={() => afterLogin()} />
            </div> :  

            <Container disableGutters={true} maxWidth="sm" >
            <ThemeProvider theme={tableTheme}>
                    <MaterialReactTable
                        enableStickyHeader={true}
                        enableFullScreenToggle={true}
                        columns={adminList ? columnsAdmin : columns}
                            initialState={
                                adminList ?
                                    {
                                        isFullScreen: true, columnVisibility: { shared: false, username: false },
                                        }
                                :
                                    { isFullScreen: true, columnVisibility: { value: false } }}
                       
                            data={FData ?? []}
                        paging={false}
                        enablePagination={false}
                        state={{ isLoading }}
                        displayColumnDefOptions={{ 'mrt-row-actions': { size: 100 } }}
                        enableRowActions
                        positionActionsColumn="last"
                        
                
                        renderEditRowDialogContent={({ table, row, internalEditComponents }) => (
                            <>
                                <DialogTitle variant="h3">Setup Phrase</DialogTitle>
                                <DialogContent
                                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                                >
                     
                                </DialogContent>
                                <DialogActions>
                                </DialogActions>
                            </>
                        )}
                        onEditingRowSave={(e) => handleSaveUser(e)}
                renderRowActions={({row, table}) => (
                    <>
                        <Button onClick={() => openInNewTab(process.env.PUBLIC_URL + "/" + row.original.id)}>View</Button>
                        
                        {adminList ?
                            <Button onClick={() => {
                                if (window.confirm(`Delete this idea ?\n"${turnToPhrase(row.original.phrase)}"\nAre you sure? This cannot be undone`))
                                    handleDelete(row.original)
                            }}>Delete</Button>
                            :
                            userinfo.id != row.original.UserId ?
                                    <Button onClick={() =>  openInNewTab(process.env.PUBLIC_URL + "/buy/" + row.original.id)}>Buy</Button>
                                    :
                                    null
                            
                        }
                        {userinfo.id === row.original.UserId ?
                            <>
                            <Button color="error" onClick={() => table.setEditingRow(row)}>Change Price</Button>
                                <FormControlLabel control={switchthis(row.original)} label="for Sale" labelPlacement="start" />
                                { row.original.aproved === 1 ?  <Chip label="Aproved" color="success"   size="small" /> : <Chip label="In Review" color="warning" size="small" />  } 
                            </>
                                :
                           null
                           }
                    </>
                )}
            renderTopToolbarCustomActions={() => (
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                    {
                        userinfo ?
                            <Button
                                color="error"
                                onClick={() => {
                                    logout();
                                }}
                                variant="contained"
                            >
                                Logout
                            </Button>
                            :
                            <Button
                            color="success"
                            onClick={() => {
                                setLogin(true);
                            }}
                            variant="contained"
                        >
                            Login
                        </Button>
                            

                    }

                    <Button
                        color="primary"
                        onClick={() => {
                            openSameTab(process.env.PUBLIC_URL + "/");
                        }}
                        variant="contained"
                    >
                       Back To Galaxy
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            openInNewTab(process.env.PUBLIC_URL + "/new");
                        }}
                        variant="contained"
                    >
                        Create Idea
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            setFData([]);
                        }}
                        variant="contained"
                    >
                        Refresh Table
                    </Button>
                    {!userinfo || adminList ? 
                        null
                        :
                    
                    <Button
                        color="primary"
                        onClick={() => {
                            filter();
                        }}
                        variant="contained"
                    >
                        { filterMine ?
                            "All"
                            :
                            "Only Mine"
                        }
                        
                        </Button>
                       }
                    {isAdmin ?
                        <Button
                            color={ adminList ? "success" : "error" }
                            onClick={() => {
                                setAdminList(!adminList);
                            }}
                            variant="contained"
                        >
                          Admin
                            </Button>
                        :
                        null
                        
                        }
                
               </Box>
            )}
       
           
                    />
                
            <GettingData Admin={isAdmin} Token={props.Token} logout={props.logout}  />
            </ThemeProvider>
            </Container>
           }
        </div>
        )
};

export default Buro;

