import React from 'react'

import Galaxy3 from './Galaxy3';
import miltosG from './miltosG';
//import GalaxyMobile from './GalaxyMobile';
import ReadMore from './ReadMore'
import BuroCrat from './BuroCrat';
import BuroCratAdmin from './BuroCratAdmin';
import NewMobile from './NewMobile';
import New4 from './New4';
import Paid from './paid';
import Login from './login';
import Registerpage from './registerpage';
import Confirm from './confirm'
import Profile from './profile';
import Buy from './buy';
import Quickentry from './Quickentry';


import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { isMobile } from 'react-device-detect';


import "./App.css"

function App() {
  


  return (
    <BrowserRouter basename='/'>
      <Routes >
          <Route path="/:FilteredMessageid" Component={Galaxy3}  />
          <Route path='/*'  Component={Galaxy3} />
          <Route path='/paid' Component={Paid} />
         <Route path='/about' Component={ReadMore} />
        <Route path='/quick' Component={Quickentry} />
        <Route path='/miltosG' Component={miltosG} />
        
        
        <Route path='/login' Component={Login} />
        <Route path='/register' Component={Registerpage} />
        <Route path='/confirm/:token?' Component={Confirm} />

         <Route path='/buy/:idea?' Component={Buy} />
    
        <Route path='profile/' Component={Profile} />
          
        {!isMobile ?
          <Route path='/new' Component={New4} />
          :
          <Route path='/new' Component={NewMobile} />
        }
        {!isMobile ? <Route path='/admin' Component={BuroCratAdmin} /> : ""}
        {!isMobile ? <Route path='/listing' Component={BuroCrat} /> : ""}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
