import { React, forwardRef, useEffect, useState,  } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Header from './Header';
import BuyProcess from './buyProcess';

function Buy(props) {

    const { idea } = useParams();
    const navigate = useNavigate();
    const [phrase, setPhrase] = useState("");
    const [done, setDone] = useState(false);
    const [forSale, setForSale] = useState(true);
    
    var tuser = JSON.parse(sessionStorage.getItem("userinfo"));
    var wallet = sessionStorage.getItem("wallet");
    
    const getSingle = process.env.REACT_APP_SINGLE_REQUEST_END_POINT;
    function turnToPhrase(str) {
        return str.replace(/_/g, " ").replace(/\|/g, " ");
    }

    async function getData(idea) {
        //var user = sessionStorage.getItem("userinfo");
      
        const h = {
            'Content-Type': 'application/json',
           // "Authorization": "Bearer " + user.token
        }

        var options = {
            method: 'GET',
            headers: h,
        };

        await fetch(getSingle + idea, options)
            .then(response => response.json())
            .then(function (data) {
                if (data.ForSale === 0) setForSale(false);

                setPhrase(turnToPhrase(data.phrase));
                sessionStorage.setItem("buyInfo",
                JSON.stringify({
                    phraseData: data,
                    BuyerUserId: 0, // userId that buys
                    sellerUserId: data.user.id,// userId that sells
                    phraseId: data.id, //phrase
                    wallet: sessionStorage.getItem("wallet"), //wallet
                    forsale: false, //boolen with the intention
                    price : data.value //integer with the new price
                })
            ); 
            });
    }
    

    useEffect(() => { 
        async function fetchData() {
            if (!idea) {
                navigate("/");
            } else { 
                if (phrase == "") { 
                    await getData(idea);
                    setDone(true);
                }
                
            }
        }
        fetchData();
    });
    
    function close() {
        navigate('/' + idea)
    }
    
    return ( 
        <div className={'MainW'}>
            
            <Header bg={true} destination="/" from="about" />
            <div className={"mouse2 newbackToGalaxy"} onClick={(e) => (close())} > [ BACK TO GALAXY ] </div> 
            {forSale ?  
            <div className='profile'>
           
              <div className='left'>
                <div className='phrase'>
                       {phrase}
                </div>
                
                </div>
                <div className='right'>
                    { done ?
                    <BuyProcess idea={idea} />
                        :
                    <div> [ Please Wait ] </div>
                    }
                 </div>
                
            </div>
                :
                <div>

                <div className='loginMessage'>Sorry, this idea is not for sale</div>
                </div>
        }
        </div>
        )
}

export default Buy

