import React, { useEffect, useRef, useState } from 'react'
import LoginProcess from './loginProcess'




function Quickentry(props) {

    const textArea = useRef();
    const author = useRef();
    const [list, setList] = useState(false);
    const [data, setData] = useState();
    const [Loading, setLoading] = useState(false);
    const [Message, setMessage] = useState("");
    const [Info, setInfo] = useState(false);
    const [logged, setLogged] = useState(false);
    
    /*
     phrase: string;
        wallet: string;
        author: string;
        value: number;
        series: number;
        checkout_id: string;
        ForSale: number;
        userId: number;
    */

    const DUMB_DATA = {
        phrase: "",
        wallet: process.env.REACT_APP_OWNWALLET,
        author: "",
        value: 10000,
        series: 0,
        checkout_id: "FromMiltos",
        ForSale: 0,
        userId: 34,
        FromMiltos: 923
    }

    const saveEndPoint = process.env.REACT_APP_REQUEST_END_POINT;
    const requestEndpoint = process.env.REACT_APP_MILTOS_END_POINT;
    const deleteEndPoint = process.env.REACT_APP_MILTOS_MD_END_POINT;

    async function save() {
        if (textArea.current.value.trim() === "") { 
            setMessage("Empty Ideas, empty heads!");
            setTimeout(() => { 
                setMessage("");
            }, 1000);
            return;
        }


        DUMB_DATA.phrase = textArea.current.value;
        DUMB_DATA.author = author.current.value;
      
        const h = {
            'Content-Type': 'application/json',
        }
        var options = {
            method: 'POST',
            mode: 'cors',
            headers: h,
            body: JSON.stringify(DUMB_DATA)
        }
        
        try {
            await fetch(saveEndPoint, options)
                .then(result => result.json())
                .then(function (data) { 
                    setMessage("Saved!");
                   textArea.current.value = "";
                    author.current.value = "";
                    setTimeout(() => { 
                        setMessage("");
                    }, 1000);
                });
            
        } catch (error) { 
            setMessage("Error!");
            setTimeout(() => { 
                setMessage("");
            }, 1000);
        }
        

    }

    function change() { 
        if (!list)
            setLoading(true);
        setList(!list);

    }

    function turnToPhrase(str) {
        var a = "";
        try {
            a = str.replace(/_/g, " ").replace(/\|/g, " ");
        } catch (e) {
            a = str;
        }
        return a;
    }

    const getIdeas = async () => {
        console.log("aaa");
        console.log(requestEndpoint);
        const response = await fetch(requestEndpoint);
        const data = await response.json();
        
        setData(data);
        setLoading(false);
    }   

    useEffect(() => { 
        if (list) { 
            getIdeas();
        }
    }, [list])
    
    useEffect(() => { 
        if (sessionStorage.getItem('userinfo')) { 
            setLogged(true);
        }
    })


    async function del(id,phrase) { 
        if (window.confirm("Are you Sure? You want do delete: \n\n" + phrase + " \n\nThis is irreversible!")) { 
            var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
            const h = {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + userinfo.token
            }

            var d = {
                    id: id,
                    fromMiltos: 923
            }

            
            var options = {
                method: 'POST',
                mode: 'cors',
                headers: h,
                body: JSON.stringify(d)
            }
            
                       try {
                           await fetch(deleteEndPoint, options)
                            .then(response => response.json())
                            .then(function (data) { 
                                if (data.statusCode != 200) {
                                    setMessage("Error");
                                } else { 
                                    setMessage("Deleted!");
                                    getIdeas();
                                }
                                setTimeout(() => { 
                                    setMessage("");
                                }, 1000);
                            });
                
            } catch (error) { 
                setMessage("Error!");
                setTimeout(() => { 
                    setMessage("");
                }, 1000);
            }






 
        };
    }


    function Messages(props) {

        return (
             
                <>
                <div className="messages">
                    <div className={props.Info ? "alert-show " : "AlertfadeOut "} {...props.Info ?  "hidden" : null }> {props.message} </div>
                </div>
                </>
                    
        )
    }


    function afterLogin() { 
        setLogged(true);
    }

    return (

        <div className={'MainW'}>
            <Messages message={Message} Info={Info} setInfo={setInfo} />
            {!logged ? 
               <LoginProcess callback={afterLogin} />  
              : 
            (!list ?  
            <>
            Idea:<br></br>
            <div className='miltosText'>
                <textarea className={"miltosTextArea"} ref={textArea}></textarea>
            </div>

            <br></br>
            author:<br></br>
            <input className='input' ref={author}></input>
            <br></br>
            <br></br>
            <br></br>
            <div className={ "mouse2"}  onClick={() => save()}>[ save ]</div>
            
            <div className={"mouse2"} onClick={() => change()}>[ list ]</div>
             </>
            :
            <>
            <div>listing</div> <div className={"mouse2"} onClick={() => change()}>[ create ]</div>
            <div>
            {!Loading ? 
                data.map(function (idea) {
                    return (
                        <div className='MiltosRow'>
                            <div className={"Miltocommands mouse2"} onClick={() => del(idea.id,turnToPhrase(idea.phrase))}>[ Del ]</div>
                        <div  className='MiltosList'>{turnToPhrase(idea.phrase)}</div>
                        </div>
                    )    
                })
                            
                            :
                <div>Loading</div>
            }
            </div>
                <div className={"mouse2"} onClick={() => change()}>[ create ]</div>
            </>
            )
            }
        </div>
        
    )
}

export default Quickentry