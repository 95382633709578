import { React ,useState, useRef} from 'react';
import { isMobile } from 'react-device-detect';
import Header from './Header';
import { useNavigate } from "react-router-dom";
import Register from './register';

function Registerpage() {

    const openInSameTab = url => {
        window.open(url, '_self', 'noopener,noreferrer');
    };
    
    const navigate = useNavigate();


    function afterLogin() { 
        setTimeout(function () {
            navigate(-1);
        }, 1000);
        
    }
    return (
        <div className={'MainW'}>
               <div className={"mouse2 newbackToGalaxy"} onClick={(e) => openInSameTab('/')} > [ BACK TO GALAXY ] </div>
           
            <Register callback={afterLogin} alreadyToLogin={true}/>
            <Header bg={true} destination="/" from="about" />
        </div>
        )
}

export default Registerpage

