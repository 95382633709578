import React from 'react'
import { isMobile } from 'react-device-detect';
import { useNavigate  } from "react-router-dom";


function Header(props) {

  const navigate = useNavigate();
  
          


  function clicMoreInfo() { 
    var cv = document.getElementById('canvasDiv');
    var more = document.getElementById('MoreInfo');

    cv.hidden = true;
    more.hidden = false;
 }


  return (
    <>

        <span className="top mouse2 " onMouseDown={() => {clicMoreInfo()}} onMouseEnter={() => { }} onMouseLeave={() => { }} >HYPERURANIUM</span>
    </>
    )
}

export default Header

