import React, { useEffect, useRef } from "react";
import { useState  } from 'react'
import Header from './Header';
import { useNavigate } from 'react-router-dom'
import LoginProcess from "./loginProcess";

import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';


//const intentGW = "http://localhost:4242/create-intent"
//const intentGW = process.env.REACT_APP_PAYMENTGATEWAY
const intentGW = "https://imagi-nacao.pt:4242/create-intent"

function New() {
    const [Message, setMessage] = useState("");
    const [Info, setInfo] = useState(false);
    const [stage, setStage] = useState(0);
    const [priv, setPriv] = useState(1);
    const [sig, setSig] = useState({ signed: 0, sig: "" });
    const [serie, setSerie] = useState(0);
    const [coin, setCoin] = useState(0);
    const [userinfo, setUserinfo] = useState({ id: 0 });    
    const [wallet, setWallet] = useState("");
    
    const TotalStages = 8;
    const navigate = useNavigate();
    const idea = useRef();

    function changeStage(dir) { 
        //hide all messages
        setInfo(false);
        setMessage("");
        //can I go forward?

        if (dir === -2) {
            dir = 0;
        } else { 
            if (stage === 0) { 
                var idea = document.getElementById('idea');
                console.log("idea is empty?" + (idea.value.replace(/\s/g, "") === ""));
                if (idea.value === "") {
                    setMessage("no empty ideas");
                    setInfo(true);
                    setTimeout(() => {
                        setInfo(false);
                        }, 1500);
                    return;
                } else { 
                    setMessage("");
                }
            }
            if (stage === 2) { 
                if(sig.signed === 1 && sig.sig.replace(/\s/g, "") === "")
                var signature = document.getElementById('sig');
                if (signature) { 
                    if (signature.value === "") {
                        setMessage("no empty signatures");
                        setInfo(true);
                        setTimeout(() => {
                            setInfo(false);
                            }, 1500);
                        return;
                    } else { 
                        setMessage("");
                    }
                }
            }
            if (stage === 3) {
                if (!priv) { 
                    var price = document.getElementById('price')
                    if (price) { 
                        if (price.value === "") { 
                            setMessage("We need a price");
                            setInfo(true);
                            setTimeout(() => {
                                setInfo(false);
                                }, 1500);
                            return;
                        }else { 
                            setMessage("");
                        }
                        if (!isNumber(price.value)) { 
                            setMessage("We need a number");
                            setInfo(true);
                            setTimeout(() => {
                                setInfo(false);
                                }, 1500);
                            return;
                        }else { 
                            setMessage("");
                        }
                        
                    
                    }
                }
            }
            if (stage == 7) { 

                const stripePromise = loadStripe('pk_test_51NsMC1J4lvUbSAbb1ng2LlgX0gg2xzHveqk7SXktsQuV1lcDFonEN4GMC8bbach61NzGTJ1jhwLMXApiRmLnowgw00Nz1E6Sl9');
                
                const options = {
                    amount: getTotal().total,
                    currency: 'eur',
                    mode: 'payment',
                    appearance: {
                        /*...*/
                    },
                };
        
                function getTotal() { 
                    //vasco
                    var ideaCost = parseInt(123);
                    var mintCost ;
                    var total;
                    var label;
                    var walletType = wallet === process.env.REACT_APP_OWNWALLET;
        
                    if (walletType) {
                        mintCost = parseInt(process.env.REACT_APP_BASE_PRICE_HYP);
                        label = "Mint with HyperWallet"
                    } else { 
                        mintCost = parseInt(process.env.REACT_APP_BASE_PRICE_OWN);
                        label = "Mint with own wallet"
                    }
        
                    total = ideaCost + mintCost;
                    return {idea:ideaCost , mint: mintCost , total:total,label : label}
        
        
                }
                
              
            }

        }

        



        var stages = document.querySelectorAll('.stages');

        stages.forEach(function (stage, index) {
            stage.style.display = "none";
        });
        
        
        var nstage = stage + dir;
        var cStage = document.getElementById('stage' + nstage);
        cStage.style.display = "flex";

        if (stage => 0 && stage <= TotalStages) { 
            setStage(stage + dir)
        }
    }

    function checkNext() { 
    
        //stage !== 6 && (wallet != "" && stage === 4)  && stage < TotalStages
        if (stage  === 0 ) return true;
        if (stage === 1) return true;
        if (stage === 2) return true;
        if (stage === 3) return true;
        if (stage === 4) return true;
        
        if (stage === 5 && wallet !== "") return true;
        if (stage === 8) return false;
        return false;
    }

    function checkPrev() { 
 
        if (stage === 8) return false;
        if (stage > 0) return true;
        return false;
    }

    function Control({ stage }) { 
      return(
          <div className="layerControls">
              { checkPrev() > 0 ?  <div className="Prev Btn mouse2" onMouseDown={() => {changeStage(-1) }}>{"Prev"} </div> : null}
              
              {checkNext() ? <div className="Next Btn mouse2" onMouseDown={() => { changeStage(1); }}> {"Next"}  </div> : null}
             
          </div>
     )    
    }

    function Messages(props) {
        console.log(props.Info);

        function out() { 
        
        
        }



        return (
             
                <>
                <div className="messages">
                    <div className={props.Info ? "alert-show " : "AlertfadeOut "} {...props.Info ?  "hidden" : null }> {props.message} </div>
                </div>
                </>
                    
        )
    }
    
    function WalletConnectDivs({ serie,priv,handlePriceChange }) {
        
        function ownWallet() { 
            setWallet(process.env.REACT_APP_OWNWALLET);
        }

        function clearOwnWallet() { 
            setWallet("");
        }



        async function ConnectToWallet(setWallet,priv,serie,handlePriceChange) {


            if (typeof window.ethereum !== 'undefined') {
                // Do something
                window.ethereum.request({ method: 'eth_requestAccounts' })
                    .then(res => {
                        // Return the address of the wallet
                        setWallet(res[0]);
                        handlePriceChange(priv,serie,res[0]);
                    })
        
                window.ethereum.on('accountsChanged', handleAccountsChanged);
        
                function handleAccountsChanged(accounts) {
                    if (accounts.length === 0) {
                        setWallet("");
                        handlePriceChange(priv,serie,"");
                    }
                }
                function handAccountDisconnect() { 
                    setWallet("");
                    handlePriceChange(priv,serie,"");
                }
        
                window.ethereum.on('disconnect',handAccountDisconnect);
        
        
            } else {
                console.log("install metamask extension!!")
            }
        }

      

        return (
            <div className="newOptions_class"  >
                   {wallet === "" ?
                    <span >
                        <span className="mouse selected" onMouseDown={() => { ConnectToWallet(setWallet, serie, priv, handlePriceChange) }}>[ Connect to your Wallet ]</span>
                        <br />or<br />
                        <span className="mouse selected" onMouseDown={() => { ownWallet() }}>[ Connect to HyperWallet ]</span>
                    </span>
                    :
                    wallet === process.env.REACT_APP_OWNWALLET ?
                        <div> You have chosen to mint your idea on HyperWallet<br /><br />
                            <span className=" mouse selected" onMouseDown={() => { clearOwnWallet() }}>Disconnect HyperWallet</span></div>
                        :
                    <div> You have chosen to mint your idea on your wallet<br /><br />
                            {wallet}<br /><br />
                    <span ></span>
                    </div>
            
                
            }
            </div>

       
            )
        
    }

    useEffect(() => {
        changeStage(-2)
        var d = JSON.parse(sessionStorage.getItem("userinfo"));
        if (d != null && userinfo.id == 0) { 
            setUserinfo(d);
        }
    }, [])

    function close() {
        setInfo(false);
        navigate('/')
    }
    
    function isNumber(value) {
        var regex = /^[0-9]+$/;
        return value.match(regex);
    }
    
    const handleSave = event => {
        var value = 0;
        if (serie === 0) value = process.env.REACT_APP_TIER0;
        if (serie === 1) value = process.env.REACT_APP_TIER1;
        if (serie === 2) value = process.env.REACT_APP_TIER2;
        if (serie === 3) value = process.env.REACT_APP_TIER3;
        var Ccurrency = "";
        if (coin === 0) Ccurrency = "eur";
        if (coin === 1) Ccurrency = "usd";
        var wwprice = 0;
        if (wallet === process.env.REACT_APP_OWNWALLET) {
            wwprice = 4000;
        } else { 
            wwprice = 2000;
        }


        fetch(`${process.env.REACT_APP_EXCHANGE}`)
        .then(currency => {
            return (currency.json());
        }).then(displayResults);

        function displayResults(currency) { 
            console.log(currency.rates["EUR"]);
            if (coin === 0) { 
                value = value * currency.rates["EUR"];
                wwprice = wwprice * currency.rates["EUR"];
            }
    
    
    
       
            changeStage(1);
        }

        
    };

    function afterLogin() {
        setTimeout(function () {
            setUserinfo(JSON.parse(sessionStorage.getItem("userinfo")));
        },2000);
    }


    const CheckoutForm = (props) => {
        const stripe = useStripe();
        const elements = useElements();
      
      
       
        const [errorMessage, setErrorMessage] = useState(null);
        const [Loading, setLoading] = useState(0);
      
        const handleSubmit = async (event) => {
          //event.preventDefault();
       
            setLoading(1);
            
          if (elements == null) {
            return;
          }
      
          // Trigger form validation and wallet collection
          const {error: submitError} = await elements.submit();
          if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            return;
          }
            
          elements.on('ready', () => {
            console.log("READY")
        })
            
      
            
                var value = 0;
                if (serie === 0) value = process.env.REACT_APP_TIER0;
                if (serie === 1) value = process.env.REACT_APP_TIER1;
                if (serie === 2) value = process.env.REACT_APP_TIER2;
                if (serie === 3) value = process.env.REACT_APP_TIER3;
                var Ccurrency = "EUR";
                var wwprice = 0;
                if (wallet === process.env.REACT_APP_OWNWALLET) {
                    wwprice = 4000;
                } else { 
                    wwprice = 2000;
                }

            

            var ddata = {
                price:value,
                phrase:idea.current.value,
                sig:document.getElementById('sig') ? document.getElementById('sig').value  : "",
                wallet:wallet,
                type:serie,
                priv:priv,
                currency:Ccurrency,
                Wprice:wwprice,
                sprice: document.getElementById('price') ? document.getElementById('price').value*100 : 0 ,
                HWallet:wallet === process.env.REACT_APP_OWNWALLET ? true : false,
                userId:userinfo.id,
            }



            await fetch(intentGW, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
                body: JSON.stringify({data:ddata, token:userinfo.token }),
          }).then(response => response.json())
            .then(async function(data) {
                const clientSecret = data.clientSecret
             
                const { error, paymentIntent } = await stripe.confirmPayment({
                    
                    //`Elements` instance that was used to create the Payment Element
                    clientSecret,
                    elements,
                    confirmParams: {
                        return_url: "https://hyperuranium.com",
                    },
                    redirect: "if_required",
                });
                

                  if (error) {
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    setErrorMessage(error.message);
                  } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                      // site first to authorize the payment, then redirected to the `return_url`.
                      setErrorMessage("Paid!");
                      setTimeout(() => {
                        changeStage(1);
                      }, 1000);
       
                      
                    }
                

            });
      
            
         
        };

        

      
        const paymentElementOptions = {
            amount: props.total,
            currency: 'eur',
            layout: 'tabs',
            paymentMethodOrder: ['card'],
        }

         //vasco
        return (
            <>
          <form >  
             <PaymentElement options={paymentElementOptions} />
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
                </form>
               
                {!Loading  ?     
                    <span className='mouse2 paybutton' onClick={(e) => handleSubmit()}>[ Pay { euro.format(parseInt(props.total / 100)) }  ]</span> 
                    :
                <span className='mouse2 paybutton'> [ Please wait  ]</span> 
                    
            }
            </>
        );
    };
    
    let euro = Intl.NumberFormat('en-DE', {
        style: 'currency',
        currency: 'EUR',
    });



    function Stage7(props) {

        const [totals, setTotals] = useState(getTotal);

        useEffect(() => { 
           
        }, [])

        


        const stripePromise = loadStripe('pk_test_51NsMC1J4lvUbSAbb1ng2LlgX0gg2xzHveqk7SXktsQuV1lcDFonEN4GMC8bbach61NzGTJ1jhwLMXApiRmLnowgw00Nz1E6Sl9');
        //vasco
        console.log(getTotal().total)
        const options = {
            amount: getTotal().total,
            currency:  'eur',
            mode: 'payment',
            appearance: {
                /*...*/
            },
        };

        function getTotal() { 
            var ideaCost = parseInt(process.env.REACT_APP_TIER0);
            var mintCost ;
            var total;
            var label;
            var walletType = wallet === process.env.REACT_APP_OWNWALLET;

            console.log(walletType);
            if (walletType) {
                mintCost = parseInt(process.env.REACT_APP_BASE_PRICE_HYP);
                label = "Mint with HyperWallet"
            } else { 
                mintCost = parseInt(process.env.REACT_APP_BASE_PRICE_OWN);
                label = "Mint with own wallet"
            }

            total = ideaCost + mintCost;
            return {idea:ideaCost , mint: mintCost , total:total,label : label}


        }
        
      

        return (
        <>
                {totals ? 
                     <div className='buy2'>
            <Elements stripe={stripePromise} options={options}>
                <div className='payinfo'>
                    <table>
                    <tbody>
                        <tr>
                            <td>{totals.label}</td>
                                            <td> { euro.format(parseInt(totals.mint / 100))}
                                                
                                            </td>
                                            </tr>
                        <tr>
                            <td>Idea</td>
                                            <td>
                                            {euro.format(totals.idea / 100)}
                                                
                                            
                                            </td>
                        </tr>
                        <tr>
                            <td>Total</td>
                                            <td>
                                                    {euro.format(parseInt(totals.total / 100))}
                                                </td>
                        </tr>
                        </tbody>
                </table>
                </div>
                    <br />
                        <CheckoutForm   total={getTotal().total} />
                        </Elements >
                        </div>
                : 
            null
                }
                </>
          );
    }

    const open = url => {
        window.open(url, "_self");
    };



    return (
        <div className={'MainW'}>
            <Messages message={Message} Info={Info} setInfo={setInfo} />
            <Header bg={1} destination="/about" />
            <Control stage={stage} />
            <div className={"mouse2 newbackToGalaxy"} onClick={(e) => (close())} > [ BACK TO GALAXY ] </div>
            <div className="stages" id="stage0">
                  <textarea
                        ref={idea}
                        //maxLength="240" This is a really really bad idea
                        id="idea"
                        //onChange={handleChange1}
                        //value={Phrase}
                        autoFocus 
                        onFocus={function(e) {
                            var val = e.target.value;
                            e.target.value = '';
                            e.target.value = val;
                        }}
                        placeholder="<please write your idea>"
                        className="textArea"
                        />
                </div>
                <div className={"stages Newstage1"}  id="stage1">
                {`[ You should only mint your original ideas. You can also mint ideas of others, duly attributing them to their author, only if they are no longer covered by copyright or, if they are, with the author’s express authorization. Failing this, your idea, while minted, will not be published.  ]`}
                </div>
                <div className="stages" id="stage2">
                <div className="wouldYou">
                    Would you like to:
                 
                        <div className="options">
                        <div className={sig.signed ? "seriesOn" : "seriesOff"} onClick={() => { setSig({signed:1, sig:""}); }}>
                             Attribute the idea  
                        </div>
                        or
                        <div className={!sig.signed ? "seriesOn" : "seriesOff"} onClick={() => { setSig({signed:0, sig:""}); }}>
                            remain anonymous 
                            </div>
                    </div>
                    <div className="sigdiv">
                    {sig.signed ?   
                            <input className="sig" id="sig" placeholder='sign'  maxLength="40" autoFocus 
                            onFocus={function(e) {
                                e.target.placeholder = '';
                                }}
                            onChange={function(e) {
                                if (e.target.value === "") { 
                                    e.target.placeholder = 'Author';
                                }
                                }}
                            onBlur={function(e) {
                                if (e.target.value === "") { 
                                    e.target.placeholder = 'Author';
                                }
                            }}
                            />
                                :
                                null    
                            }
                        </div>
                        </div> 
                 
                    
            </div>
            <div className="stages" id="stage3">
            <div className="wouldYou">
                        <div className="inside" > Would you like to mint it to : </div>
                        <br />
                        <div className="options">
                        <span className={priv ? "seriesOn" : "seriesOff"} onClick={() => { setPriv(1) }}>keep it to yourself</span>
                        or
                    <span className={!priv ? "seriesOn" : "seriesOff"} onClick={() => { setPriv(0) }}> put it for sale</span>
                    </div>
                    
                    <div className="Moneydiv">
                        {!priv ? 
                            <>
                                { /*check if format number ! */ }
                                <input className="sig" placeholder='price' id='price' maxLength="10" autoFocus 
                                onFocus={function(e) {
                                    e.target.placeholder = 'price';
                              
                                }}
                                onChange={function(e) {
                                    if (e.target.value === "") { 
                                        e.target.placeholder = 'price';
                                    }
                                }}
                                onBlur={function(e) {
                                    if (e.target.value === "") { 
                                        e.target.placeholder = 'price';
                                    }
                                }}
                            />
                                <div className={"seriesOff coin"} >Euros</div>
                                
                            </>
                            :
                                null
                        }
                       </div> 
                                
                        </div>  
            </div>
            <div className="stages" id="stage4">
            <span>
                            <span className={serie === 0 ? "seriesOn" : "seriesOff"} onClick={() => { setSerie(0)  }} >[ unique ]</span>
                            <br />OR EDITION :
                            <br /><span className={ serie === 1 ? "seriesOn" : "seriesOff"} onClick={() => { setSerie(1)}}>[ 1/10 ]</span>
                            <br /><span className={ serie === 2 ? "seriesOn" : "seriesOff"} onClick={() => { setSerie(2)}}>[ 1/20 ]</span>
                            <br /><span className={ serie === 3 ? "seriesOn" : "seriesOff"} onClick={() => { setSerie(3)}}>[ 1/100 ]</span >
                    </span>     
            </div>
            <div className="stages" id="stage5">
                <WalletConnectDivs  />   
            </div>
            <div className="stages" id="stage6">
                {  userinfo.id === 0 ?
                <LoginProcess  callback={afterLogin}/>
                :
                <span className="mouse selected" onClick={() => { handleSave() }} > [ MINT ]</span>     
                }
            </div>
            <div className="stages" id="stage7">
                
                {stage === 7 ? 
                    <Stage7 />
                    :
                    null
                }
            
            </div>
            <div className="stages" id="stage8">
            <div className="paidThanks">
            Thanks for minting your idea on hyperuranium,  <br />
            your idea is now going throught the hyperuranium  <br />
            aproval process, before being published  <br />
             <br />
             
            </div>
         <div className='mouse2 paidButton'
                    onClick={() => {
                 
                 open("/")
             }}>
             [back]</div>
            </div>
            
        </div>
        )
}


export default New;

