import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect';


const open = url => {
    window.open(url, "_self");
};

function Paid(props) {
   
    return (
        <div className={'MainW'}>
        <div className="messages">
             
                
                Thank you for sharing you idea on HyperUranium,<br />
                <br />
                
            </div>
            <div className='mouse2 paidMessages'
                onClick={() => {
                    open("/")
                }}
            >
                [back]</div>
            </div>
        
    )
}

export default Paid

