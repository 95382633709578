import { React ,useState, useEffect, useParams} from 'react';
import LoginProcess from './loginProcess';
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { json } from 'react-router-dom';


function BuyProcess(props) {


    const [stage, setStage] = useState(1);
    const [wallet, setWallet] = useState(sessionStorage.getItem("wallet"));
    const [hideNav, setHideNav] = useState({ left: false, right: false });
    const [afterSale, setAfterSale] = useState({ newprice: 0, forsale: 0 });
    
    const DataEndPoint = process.env.REACT_APP_DATA_END_POINT;

    async function checkLogged(userinfo) { 
        

        var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));

        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + userinfo.token },
        };

       
        await fetch(DataEndPoint + userinfo.id ,requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if (data.statusCode === 401) { 
                    sessionStorage.removeItem("userinfo");
                    sessionStorage.removeItem("wallet");
                    //openInSameTab("/");
                   
                }
            
            });



    }


    const paymentGW = process.env.REACT_APP_PAYMENTGATEWAY_END_POINT;
    const sellEndPoint = process.env.REACT_APP_SELL_END_POINT;
    
    const intentGW = process.env.REACT_APP_PAYMENTGATEWAY
    
    const { idea } = props;

    const openInSameTab = url => {
        window.open(url, '_self', 'noopener,noreferrer');
    };

    function ownWallet() { 
        setWallet(process.env.REACT_APP_OWNWALLET);
        var buyInfo = JSON.parse(sessionStorage.getItem("buyInfo"));
        var m = JSON.stringify({
            phraseData: buyInfo.phraseData,
            BuyerUserId: buyInfo.BuyerUserId, // userId that buys
            sellerUserId: buyInfo.sellerUserId,// userId that sells
            phraseId: buyInfo.phraseId, //phrase
            wallet: process.env.REACT_APP_OWNWALLET, //wallet
            forsale: buyInfo.forsale, //boolen with the intention
            price: buyInfo.price //integer with the new price
        });
        sessionStorage.setItem("buyInfo",m)
       
        console.log(m)
        setHideNav({ left: hideNav.left, right: true });
    }

    async function ConnectToWallet(setWallet) {

        if (typeof window.ethereum !== 'undefined') {
            // Do something
            console.log("Encontrei");
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(function (res) { 
                    // Return the address of the wallet
                    setWallet(res[0]);
                   
                    var buyInfo = JSON.parse(sessionStorage.getItem("buyInfo"));
                    buyInfo.wallet = res[0]
                    console.log(buyInfo);
                    //sessionStorage.setItem("buyInfo",JSON.stringify(buyInfo));
                    });
    
                    window.ethereum.on('accountsChanged', handleAccountsChanged);
    
            function handleAccountsChanged(accounts) {
                if (accounts.length === 0) {
                    setWallet("");
                }
            }
    
    
    
        } else {
            console.log("install metamask extension!!")
        }
    }

    useEffect(() => { 
        console.log("mudou! wallet")
        if (wallet != "") {
            sessionStorage.setItem("wallet", wallet);
        } else { 
            sessionStorage.removeItem("wallet");
        }
        var buyInfo = sessionStorage.getItem("buyInfo");
        console.log("..........");
        console.log(JSON.parse(buyInfo));
        console.log("..........");

    }, [wallet]);


    useEffect(() => { 
        var buyInfo = sessionStorage.getItem("buyInfo");
        console.log("<B>");
        console.log(JSON.parse(buyInfo));
        console.log("</B>");
    })


    function WalletConnectDivs() {


        return (
            <div className="newOptions_class"  >
                {wallet  ?  
                <span
                   
                        >
                        
                        {
                            wallet === process.env.REACT_APP_OWNWALLET ? 
                                <div>
                                You are connected to the Hyperwallet<br /><br /> <span onMouseDown={() => {
                                setWallet("");
                                sessionStorage.removeItem("wallet");
                                setHideNav({ left: hideNav.left, right: false });
                            
                            
                        }}  className="mouse2 colorWallet" >Disconnect</span>
                                </div>
                                :
                                <div>
                                    You are connected to your wallet <br />  { wallet} <br /> <span onMouseDown={() => {
                            if (sessionStorage.getItem("wallet") !== "") { 
                                setWallet("");
                                sessionStorage.removeItem("wallet");
                                setHideNav({ left: hideNav.left, right: false });
                            }
                            
                        }}  className="mouse2 colorWallet" >Disconnect</span>
                                </div>
                        }
                </span>
                :
                <span
                    
                    >
                        <span   className="mouse2 selected" onMouseDown={() => { ConnectToWallet(setWallet) }}>[ Your Wallet ]</span><br />
                        or<br />
                        <span  className="mouse2 selected" onMouseDown={() => { ownWallet() }}>[ HyperWallet ]</span>
                </span>
                }
                
            </div>
        )
    }


    function Stage2() { 

        useEffect(() => { 
            if(!hideNav.right)
                setHideNav({ left: false,  right: true });
        }, [])
        
        return (
            
            <WalletConnectDivs />
        )
    }


    function logout() { 
        sessionStorage.removeItem("userinfo");
        sessionStorage.removeItem("wallet");
        setStage(1);
    }



    const CheckoutForm = (props) => {
        const stripe = useStripe();
        const elements = useElements();
      
      
       
        const [errorMessage, setErrorMessage] = useState(null);
        const [Loading, setLoading] = useState(0);
      
        const handleSubmit = async (event) => {
          //event.preventDefault();
       
            setLoading(1);
            
          if (elements == null) {
            return;
          }
      
          // Trigger form validation and wallet collection
          const {error: submitError} = await elements.submit();
          if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            return;
          }
            
          elements.on('ready', () => {
            console.log("READY")
        })
            
            var buyinfo = JSON.parse(sessionStorage.getItem("buyInfo"));
            var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
            
            await fetch(intentGW, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
                body: JSON.stringify({data:buyinfo, token:userinfo.token , total:buyinfo.price}),
          }).then(response => response.json())
            .then(async function(data) {

                const clientSecret = data.clientSecret
             
                const { error, paymentIntent } = await stripe.confirmPayment({
                    
                    //`Elements` instance that was used to create the Payment Element
                    clientSecret,
                    elements,
                    confirmParams: {
                        return_url: "https://hyperuranium.com",
                    },
                    redirect: "if_required",
                });
                

                  if (error) {
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    setErrorMessage(error.message);
                  } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                      // site first to authorize the payment, then redirected to the `return_url`.
                  
                  
                        
                      setStage(4);
                          
                    }
                

            });
      
            
         
        };
      
        const paymentElementOptions = {
            amount: props.total,
            currency: 'eur',
            layout: 'tabs',
            paymentMethodOrder: ['card'],
        }
         //vasco
        return (
            <>
          <form >  
             <PaymentElement options={paymentElementOptions} />
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
                </form>
               
                {!Loading  ?     
                <span className='mouse2 paybutton' onClick={(e) => handleSubmit()}>[ Pay {euro.format(parseInt(props.total / 100))}]</span> 
                    :
                <span className='mouse2 paybutton'> [ Please wait  ]</span> 
                    
            }
            </>
        );
    };
    
    let euro = Intl.NumberFormat('en-DE', {
        style: 'currency',
        currency: 'EUR',
    });

    function Stage3(props) {

        const [totals, setTotals] = useState(getTotal);

        useEffect(() => { 
            if (!hideNav.left) { 
                setHideNav({ left: true, right: false });
            }
        }, [])

        


        const stripePromise = loadStripe('pk_test_51NsMC1J4lvUbSAbb1ng2LlgX0gg2xzHveqk7SXktsQuV1lcDFonEN4GMC8bbach61NzGTJ1jhwLMXApiRmLnowgw00Nz1E6Sl9');
        //vasco
    
        
        const options = {
            amount: getTotal().total,
            currency: 'eur',
            mode: 'payment',
            appearance: {
                /*...*/
            },
        };

        function getTotal() { 
            var buyInfo = JSON.parse(sessionStorage.getItem("buyInfo"));
           
            var ideaCost = parseInt(buyInfo.price);
            var mintCost ;
            var total;
            var label;
            var walletType = sessionStorage.getItem("wallet") === process.env.REACT_APP_OWNWALLET;

            if (walletType) {
                mintCost = parseInt(process.env.REACT_APP_BASE_PRICE_HYP);
                label = "Mint with HyperWallet"
            } else { 
                mintCost = parseInt(process.env.REACT_APP_BASE_PRICE_OWN);
                label = "Mint with own wallet"
            }

            total = ideaCost + mintCost;
            return {idea:ideaCost , mint: mintCost , total:total,label : label}


        }
        
      

        return (
        
            
             <>
            {totals ? 
            <Elements stripe={stripePromise} options={options}>
                <div className='payinfo'>
                    <table>
                    <tbody>
                        <tr>
                            <td>{totals.label}</td>
                            <td>{euro.format(parseInt(totals.mint / 100))}</td>
                        </tr>
                        <tr>
                            <td>Idea</td>
                            <td> {euro.format(totals.idea/100 )} </td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{euro.format(parseInt(totals.total /100)) }</td>
                        </tr>
                        </tbody>
                </table>
                </div>
                    <br /><br />
                        <CheckoutForm  total={getTotal().total} />
                     </Elements >
                
                : 
            null
               }
                </>
       

          );
    }

    

    function Stage4() { 

        var d = JSON.parse(sessionStorage.getItem("buyInfo"));
        return (
            <div className='login'>
                Congratulation<br />
                you bought the idea!<br />
                <br /><br />
                <span className={afterSale.forsale === 1 ? "mouse2 seriesOn" : "mouse2 seriesOff"} onClick={() => { setAfterSale({ newprice: parseFloat(d.price), forsale: 1 }); }}>put it for sale</span>
                or <br />
                <span className={afterSale.forsale === 0 ? "mouse2 seriesOn" : "mouse2 seriesOff"} onClick={() => { setAfterSale({ newprice:  parseFloat(d.price), forsale: 0 }); }}>keep it to yourself</span>
            </div>
        )
    }

    function Stage5() { 

        var d = JSON.parse(sessionStorage.getItem("buyInfo"));
        return (
        <>
            {
                afterSale.forsale ? 
                <div>
                            <input className="email" placeholder='new price' id='price' maxLength="10" autoFocus
                                onChange={(e) => {
                                    if (e.target.value === "") e.target.value = 0;
                                    setAfterSale({ forsale: afterSale.forsale, price: parseFloat(e.target.value) * 100 }) }
                                } 

                                value={parseFloat(afterSale.price)/100 ? parseFloat(afterSale.price)/100 : 0}
                            /><br />
                            current price<br/>
                            {parseFloat(d.price)/100 } €
                 </div>       
                        :
                        setStage(6)
                }
                </>
         )
    }

    function Stage6() {
       
        useEffect(() => { 
            if (hideNav.left != false || hideNav.right != false) { 
                setHideNav({ left: false, right: false });
            }
        },[]);
        


    return(
        <div>
            you can now manage your <br />
            idea at <span className='mouse2 underline' onClick={(e) => openInSameTab("/Listing")} >the Listing Page </span>
        </div>
    )
    }


    async function handlerAfterSale() { 
        var d = JSON.parse(sessionStorage.getItem("buyInfo"));
        var userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
        afterSale.id = d.phraseId;
        
          const h = {
              'Content-Type': 'application/json',
              "Authorization": "Bearer " + userinfo.token
          }
          var options = {
              method: 'POST',
              headers: h,
              body: JSON.stringify(afterSale)
          }
        
        await fetch(paymentGW, options)
          .then(response => response.json())
            .then(function (data) { 
                if (data.name === "TokenExpiredError") { 
                    logout()
                }
                setStage(6);
                
            })
        
        
    }

    function Navigation(props) { 
        
        const { hideNav} = props;
        
        return (
            <div className='buyfooter'>
                 
                <div className='bleft'>
                { ( stage > 2 && stage != 4 && stage == 5) && 
                        <span onClick={(e) => prev() } className='mouse2'>Prev</span>
                }
               </div>
                
                <div className='bright'>
                    { ( stage < 5 && stage != 3 )  && <span onClick={(e) => next()} className='mouse2'>Next</span>}
                    {stage === 5 && <span className='mouse2' onClick={(e) => handlerAfterSale()} >Save</span>}
                </div>
            </div>
        )
    }




    function afterLogin() {
        var d = JSON.parse(sessionStorage.getItem("userinfo"));
        var b = JSON.parse(sessionStorage.getItem("buyInfo"));
        b.BuyerUserId = d.id;
        sessionStorage.setItem("buyInfo", JSON.stringify(b));
        sessionStorage.removeItem('wallet');
        setTimeout(() => {
            setStage(2);
        }, 3000);

    }

    function next() { 
        console.log(JSON.parse(sessionStorage.getItem("userinfo")));
        setStage(stage+1);
    }


    function prev() { 
        setStage(stage-1);
    }





    return (
    <div className='buyMain'>
            {stage === 1 && <LoginProcess callback={afterLogin} />}
            {stage === 2 && <div className='buy2'><Stage2 /></div>}
            {stage === 3 && <div className='buy2'>
        
                <Stage3 idea={idea} buyInfo={sessionStorage.getItem("buyInfo")} />
            </div>}
            {stage === 4 && <div className='buy2'><Stage4 /></div>}
            {stage === 5 && <div className='buy2'><Stage5 /></div>}
            {stage === 6 && <div className='buy2'><Stage6 /></div>}
            {stage > 1 && <Navigation hideNav={hideNav} />}     
                </div>
    )
}



export default BuyProcess