import React, { useEffect } from "react";
import { useState  } from 'react'
import Header from './Header';
import Keyboard from "./Keyboard";
import { Canvas } from '@react-three/fiber'
import Vk from "./vk"
import MetaMaskSDK from "@metamask/sdk";

new MetaMaskSDK({
    useDeeplink: false,
    communicationLayerPreference: "socket",
});


const open = url => {
    window.open(url, "_self", 'noopener,noreferrer');
};

const saveEndPoint = process.env.REACT_APP_SAVE_END_POINT;
const paymentGateway = process.env.REACT_APP_PAYMENTGATEWAY


const queryParameters = new URLSearchParams(window.location.search)
var success = queryParameters.get("success");

function MintOptions({  handleSave, Phrase, Sig, setFocus, setkeys , keys }) {
   
    function MintIt() {
        handleSave();
    }


    function turnToPhrase(str) {
        return str.replace(/_/g, " ").replace(/\|/g, " ");
    }


    return (
        <div className="newOptions_class" >
            <span className="mouse" >
                Signature<br /><br />
                <div id="spanSig" onClick={() => { setFocus(0); setkeys(true) }} className="input_mobile sigInput">
                    {Sig}
                </div>
            </span><br />
            <br />
            <span className="mouse" onClick={() => { MintIt() }} > [ Pay and Go ]</span>
            <span> or </span>
            <span className="mouse" onClick={() => {
                window.localStorage.setItem("phrase", "");
                window.localStorage.setItem("sig", "");
                open(process.env.REACT_APP_PUBLIC_URL)
            }
            }> [ Close ]</span>
            <form id="payform" action={paymentGateway} method="POST">
                <input type="Hidden" name="price" value="500" readOnly />
                <input type="Hidden" name="phrase" value={ Phrase !== null ? turnToPhrase(Phrase) : "" } readOnly id="inputphrase" />
                <input type="Hidden" name="sig" value={Sig} readOnly />
            </form>
            <div className="clickmetochange" onClick={() => { setFocus(1); setkeys(true) }}>
            
            </div>
            </div>
     
    )
}

function Messages({ message,Info }) {

    

    return (
        <div className="messages">
            <div className={Info ? "alert-show " : "alert-show fadeOut " } > {message} </div>
        </div>
    )
}


function NewMobile() { 
    const [Phrase, setPhrase] = useState("");
    const [Message, setMessage] = useState("");
    const [Info, setInfo] = useState(false);
    const [Sig, setSig] = useState("");
    const [Focus, setFocus] = useState(0);
    const [keys, setkeys] = useState(false);
    const [wallet, setWallet] = useState(window.localStorage.getItem("wallet") ? process.env.REACT_APP_CONNECTED_WALLET : process.env.REACT_APP_CONNECT_WALLET);




   
    async function postData(pp, wallet,username) {
        console.log("posted");
        var jsonData = { "phrase": pp, "wallet": wallet, "username": username,  "owner": username }


        const h = {
            'Content-Type': 'application/json',
        }
        var options = {
            method: 'POST',
            mode: 'cors',
            headers: h,
            body: JSON.stringify(jsonData)
        }
        const response = await fetch(saveEndPoint, options);
        if (response.ok) {
            console.log("aqui");
            setInfo(true);
            setMessage("everything went well");
            setTimeout(() => {
                setInfo(false);
            }, 1500);
        } else {
            setMessage(" There was a problem with the minting");
            setInfo(true);
            setTimeout(() => setInfo(false), 1500);
        }

    }

   
    const handleChange = event => {
        setSig(event.target.value);

    };

    const handleSave = event => {
        if (window.localStorage.getItem("wallet") === "") { 
            setMessage("There is no wallet Connected");
            setInfo(true);
            setTimeout(() => setInfo(false), 1500);
        }
        if (Phrase === "") {
            setMessage("There are no Empty Ideas");
            setInfo(true);
            setTimeout(() => setInfo(false), 1500);
        } else { 
            window.localStorage.setItem("phrase", Phrase);
            window.localStorage.setItem("sig", Sig);
            
            var form = document.getElementById("payform");
            console.log(window.localStorage);
            
            form.submit();
 
            
        }
      
        
    };

    useEffect(() => { 
        if (window.localStorage.getItem("phrase") === null) window.localStorage.setItem("phrase", "");
        if (Phrase === null) setPhrase("");
        if (Sig === null) setSig("");
        if (window.localStorage.getItem("sig") === null) window.localStorage.setItem("sig", "");

        if (window.localStorage.getItem("phrase") !== "" && Phrase === "") { 
            setPhrase(window.localStorage.getItem("phrase"));
            setSig(window.localStorage.getItem("sig"))
        }
        if (success) { 
            postData(
                window.localStorage.getItem("phrase"),
                window.localStorage.getItem("wallet"),
                window.localStorage.getItem("sig"));
        }
 
       

    });

    window.addEventListener('load', function () { 
        console.log("loaded");
    })
   
    async function ConnectToWalletMob(setWallet) {
        console.log(window.localStorage.getItem("wallet"));
        if (window.localStorage.getItem("wallet") !== "" && window.localStorage.getItem("wallet") !== null) { 
            return
        }

        if (typeof window.ethereum !== 'undefined') {
            // Do something
            console.log("Encontrei");
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(res => {
                    // Return the address of the wallet
                    console.log(res)
                    setWallet(process.env.REACT_APP_CONNECTED_WALLET);
                    window.localStorage.setItem("wallet", res[0]);
                })

            window.ethereum.on('accountsChanged', handleAccountsChanged);

            function handleAccountsChanged(accounts) {
                if (accounts.length === 0) {
                    setWallet(process.env.REACT_APP_CONNECT_WALLET);
                    window.localStorage.setItem("wallet", "");
                }
            }



        } else {
            console.log("install metamask extension!!")
            setWallet(process.env.REACT_APP_NO_METAMASK);
        }
        
    }
  


    return (
        <>
            <Canvas linear camera={{ position: [0, 0, 5] }}>
            <color attach="background" args={['#ffffff']} />
                <ambientLight />
               
                <Keyboard phrase={Phrase === null ? "" : Phrase} changePhrase={setPhrase} tokenNize={true} position={[0, 0, 0]} Mobile={true} setFocus={setFocus} />
            
            </Canvas>
            <Header bg={1} destination="/about" />
            <MintOptions handleChange={handleChange} handleSave={handleSave} Phrase={Phrase} setSig={setSig} Sig={Sig} setFocus={setFocus} keys={keys} setkeys={setkeys} />
            <Messages message={Message} handleChange={handleChange} Info={Info} setInfo={setInfo} />
            { keys ?    
                <Vk changePhrase={setPhrase} Phrase={Phrase === null ? "" : Phrase} Focus={Focus} setFocus={setFocus} setSig={setSig} Sig={Sig} setkeys={setkeys} />
            :
            <span className="mouse colorWallet walletMobile" onMouseDown={() => { ConnectToWalletMob(setWallet) }}>{wallet}</span>
            }
           
        </>
        )
}


export default NewMobile;

