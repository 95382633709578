import { React, useEffect,useRef ,useState} from 'react';
import { isMobile } from 'react-device-detect';
import Header from './Header';
import { useNavigate  } from "react-router-dom";


function Profile(props) {

    const [message, setMessage] = useState("");
    const [hidden, setHidden] = useState(true);
    const [email, setEmail] = useState("");
    const [paypal, setPaypal] = useState("");
    const [oldpassword, setOldPassword] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");



    const DataEndPoint = process.env.REACT_APP_DATA_END_POINT;
    const SaveEndPoint = process.env.REACT_APP_SAVEUSER_END_POINT;

    var userinfo;
    
    const openInSameTab = url => {
        window.open(url, '_self', 'noopener,noreferrer');
    };

    async function checkLogged(userinfo) { 
        
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + userinfo.token },
        };

       
        await fetch(DataEndPoint + userinfo.id ,requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if (data.statusCode === 401) { 
                    sessionStorage.removeItem("userinfo");
                    openInSameTab("/login");
                   
                }
                setEmail(data.email);
                setPaypal(data.paypal);
            });



    }

    async function saveData1() { 
        userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
        const requestOptions = {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userinfo.token },
            body: JSON.stringify({
                "id" : userinfo.id,
                "paypal" : paypal,
            })}
        
        await fetch(SaveEndPoint ,requestOptions)
        .then(response => response.json())
            .then(function (data) {
                if(data.id){ 
                    setMessage("success");
                    setTimeout(() => {
                        setMessage("");
                    }, 1000);
                }
        });


    };

    async function saveData2() { 
        userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
        const requestOptions = {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userinfo.token },
            body: JSON.stringify({
                "id" : userinfo.id,
                "oldpassword": oldpassword,
                "password": password1,
                "password2": password2,
            })}
        if (password1 != password2 || password1 === "" || password1 === "" || oldpassword === "" ) {
            if (oldpassword === "") {
                setMessage("Current Password Can't be blank");
                setTimeout(() => {
                    setMessage("");
                    return;
                }, 1000);
            } else { 
                setMessage("Passwords Don't match or blank" );
                setTimeout(() => {
                    setMessage("");
                    return;
                }, 1000);
            }
        } else { 
            await fetch(SaveEndPoint ,requestOptions)
            .then(response => response.json())
                .then(function (data) {
                    if (data.message) { 
                        setMessage(data.message);
                        setTimeout(() => {
                            setMessage("");
                        }, 1000);
                    }
                    if (data.id) { 
                        setMessage("success");
                        setTimeout(() => {
                            setMessage("");
                        }, 1000);
                       
                    }
                    
            });
        }
    }

    useEffect(() => {
        userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
        if (userinfo?.token) {
            setHidden(false);
            checkLogged(userinfo)
        } else { 
            setTimeout(() => {
                openInSameTab("/login");
            }, 5000);
        }
        
        }, []);

    


    return (
         
        < div className={'MainW'} >
             <Header bg={true} destination="/" from="about" />
             <div className={"mouse2 newbackToGalaxy"} onClick={(e) => openInSameTab('/')} > [ BACK TO GALAXY ] </div>
            {!hidden ?
                <>
            <div className='house'>
             <div className='Email'>
                {email}  
            </div>    
             <div className='profile'>
             <div className="loginMessage" >{message}</div>
                <div className='left'>
               paypal<input type="text" value={paypal} onChange={e => setPaypal(e.target.value)} /><br />
               <div className={'mouse2 register btt'} onClick={(e) => saveData1()} >[Save]</div>
            
               </div>
               <div className='right'>
                Current Password:<input autoComplete='password' type="password" value={oldpassword} onChange={e => setOldPassword(e.target.value)} ></input><br />
                password<input type="password" value={password1} onChange={e => setPassword1(e.target.value)}></input><br />
                retype password<input type="password" value={password2} onChange={e => setPassword2(e.target.value)}></input><br />
                <div className={'mouse2 register btt'} onClick={(e) => saveData2()} >[Change Password]</div>
                </div>
                        </div>
                        </div>
                </>
                 :
                <>
                <div className='loginMessage'>You must be logged in to reach this part of the galaxy</div>
                </>
            }
                </div >
         
        )
}

export default Profile

