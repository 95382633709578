import React, { useState, useMemo,useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import useEventListener from '@use-it/event-listener'
import { useFrame, useThree } from '@react-three/fiber'


var letters = [];

var flag = 0;
var dimension = 0;

/*
function BlinkBar() { 
    const [visible, setVisible] = useState(true);

    const { nodes } = useGLTF("/models/keyboard.glb");
    var slash = nodes.slash;   
    slash.scale.set(.1, .1, .1);
    slash.rotation.set(90, 0, 0);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisible(!visible);
        }, 500);

        return () => clearInterval(interval);
    }, [visible]);


    // position={props.fixed ? [y, x, -.06 * (p.length - totalReturns())] : [!props.Mobile ? y : y-1, !props.Mobile ?  x : x-.1, !props.Mobile ? -1 : -3.5]}

    return (
        <primitive
            object={slash}
            position={[-1, 0,-2]}
        >
            <meshStandardMaterial wireframe={false} color={'blue'} visible={visible} needsUpdate={true}
            />
        </primitive>
    )
}

function AddLetter(props) {

    const {nodes } = useGLTF("./models/keyboard.glb");

    letters["q"] = [nodes.q];
    letters["w"] = [nodes.w];
    letters["e"] = [nodes.e0, nodes.e1, nodes.e2];
    letters["r"] = [nodes.r];
    letters["t"] = [nodes.t0, nodes.t1];
    letters["y"] = [nodes.y0, nodes.y1];
    letters["u"] = [nodes.u];
    letters["i"] = [nodes.i];
    letters["o"] = [nodes.o];
    letters["p"] = [nodes.p];
    //second row
    letters["a"] = [nodes.a];
    letters["s"] = [nodes.s];
    letters["d"] = [nodes.d];
    letters["f"] = [nodes.f];
    letters["g"] = [nodes.g];
    letters["h"] = [nodes.h0, nodes.h1];
    letters["j"] = [nodes.j0, nodes.j1];
    letters["k"] = [nodes.k];
    letters["l"] = [nodes.l];
    //third row
    letters["z"] = [nodes.z];
    letters["x"] = [nodes.x];
    letters["c"] = [nodes.c];
    letters["v"] = [nodes.v];
    letters["b"] = [nodes.b0, nodes.b1];
    letters["n"] = [nodes.n];
    letters["m"] = [nodes.m];
    letters["_"] = [nodes._];
    letters["0"] = [nodes.n0];
    letters["1"] = [nodes.n1];
    letters["2"] = [nodes.n2];
    letters["3"] = [nodes.n3];
    letters["4"] = [nodes.n4];
    letters["5"] = [nodes.n5];
    letters["6"] = [nodes.n6];
    letters["7"] = [nodes.n7];
    letters["8"] = [nodes.n8];
    letters["9"] = [nodes.n9];

    letters["!"] = [nodes.Exc0, nodes.Exc1];
    letters["@"] = [nodes.at];
    letters["#"] = [nodes.velha]
    letters["$"] = [nodes.Money]
    letters["/"] = [nodes.slash]
    letters[":"] = [nodes.coma0,nodes.coma1]
    letters["|"] = [nodes.return];
    letters["?"] = [nodes.int0, nodes.int1];
    letters["("] = [nodes.opend];
    letters[")"] = [nodes.closed];
    letters["."] = [nodes.dot];
    letters["&"] = [nodes.and];

    const p = Array.from(props.phrase);

    function getletter(lett) {
        return letters[lett]
    }

    function totalReturns() { 
        var total = 0;
        for (var i = 0; i < p.length; i++) { 
            if (p[i] === "|") total++;
        }
        return total;
    }

    var line = 0;
    var x = 0;
    var y = -1;
    return (
        <group>
            {
                p.slice().map(
                    function (element, index) {
                        const Letter = getletter(element);
                        return (
                            Letter.slice().map(
                                function (elementI, indexI) {
                                    var jhon = elementI.clone();
                                    jhon.scale.set(.1, .1, .1);
                                    jhon.rotation.set(90, 0, 0);
                                    if (element === "_" || element === "|") { 
                                        jhon.scale.set(.0, .0, .0);
                                    }
                                    if (element === "|") {
                                        line++;
                                        x = line * -.30;
                                        y = -1;
                                    } else {
                                        //letters["e"] = [nodes.e0, nodes.e1, nodes.e2];
                                        if (Letter.length === 1) {
                                            y = (y + .18);
                                        }
                                        if (Letter.length > 1 && indexI < 1) { 
                                            y = (y + .18);
                                        }
                                    }
                                    return (
                                        <primitive
                                            key={"key" + index + "_" + indexI }
                                            object={jhon}
                                            position={props.fixed ? [y, x, -.06 * (p.length - totalReturns())] : [!props.Mobile ? y : y-1, !props.Mobile ?  x : x-.1, !props.Mobile ? -1 : -3.5]}
                                        >
                                            <meshStandardMaterial wireframe={false} color={'blue'} opacity={element === "_" ? 0.0 : 1} needsUpdate={true}
                                            />
                                        </primitive>
                                    )
                                }
                            )
                        )
                    }
                )
            }


         
         </group>
    );
}

*/


function Keyboard(props) {
    const [Phrase, setPhrase] = useState("");

    function totalReturns() {
        var total = 0;
        for (var i = 0; i < Phrase.length; i++) {
            if (Phrase[i] === "|") total++;
        }
        return total;
    }


    const { scene } = useGLTF("./models/keyboard.glb");
    const copiedScene = useMemo(() => scene.clone(), [scene])

    copiedScene.position.set(0.9, .2, -.5 );

    const box = new THREE.Box3().setFromObject(copiedScene);
    const center = box.getCenter(new THREE.Vector3());

    const camera = useThree((state) => state.camera);

    if (!props.Mobile) {
        var pos = new THREE.Vector3();
        pos.x = copiedScene.position.x - center.x + 2;
        pos.y = copiedScene.position.y - center.y - 0.5 * totalReturns();
        pos.z = (copiedScene.position.z - center.z - 1) + .1 * (Phrase.length - totalReturns());
    
        
        //camera.position.lerp(pos, 0.5)
        //camera.updateProjectionMatrix()
    }


    function handler(e) {
        var key = e.key;
        var maxSize = 222;
        var lineMax = 25;
        if (dimension > lineMax) flag = 1;
       
        if (props.tokenNize) {
            console.log(key);
            if (key === " ") key = "_";
            if (key === "Enter") {
                key = "|";
                dimension = 0;
                flag = 0;
            } 
            
            if (!e.metaKey && !e.ctrlKey) { 
                if (flag === 1 && key === "_") {
                    key = "|";
                    setPhrase(Phrase + key.toLowerCase());
                    window.localStorage.setItem("phrase", Phrase + key.toLowerCase());
                    props.changePhrase(Phrase + key.toLowerCase());
                    console.log("pus o enter");
                    dimension = 0;
                    flag = 0;
                } else { 
                    if (letters[key.toLowerCase()] != null && key.toLowerCase().length === 1 && Phrase.length < maxSize) {   
                        setPhrase(Phrase + key.toLowerCase());
                        props.changePhrase(Phrase + key.toLowerCase());
                        window.localStorage.setItem("phrase", Phrase + key.toLowerCase());
                        dimension++;
                    } else { 
                        if (key === "Backspace") { 
                            setPhrase(Phrase.substring(0, Phrase.length - 1));
                            props.changePhrase(Phrase.substring(0, Phrase.length - 1));
                            window.localStorage.setItem("phrase", Phrase.substring(0, Phrase.length - 1));
                            dimension--;
                            if (dimension < 0) dimension = 0;
                        }
                    }
                }
            }
        }
        
    }

    useEventListener('keydown', handler); 

    useEffect(() => {
        if (window.localStorage.getItem("phrase")) { 
            setPhrase(window.localStorage.getItem("phrase"));
            const ret = window.localStorage.getItem("phrase").split('|');
            dimension = ret[ret.length - 1].length - 1;
            flag = 0;
        }
        });

    //setFocus={setFocus}
    return (
        <group
            scale={4}
        >
            <primitive object={copiedScene} needsUpdate={true}  />
            
        </group>
        );
}


export default Keyboard;