import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import BuroCrat from './BuroCrat';
import CryptoJS from "crypto-js";

const AuthEndPoint = process.env.REACT_APP_AUTH_END_POINT;


export default function BAdmin() {
    const [message, setMessage] = React.useState("");

   
    const logout = e => {
        window.localStorage.setItem("Token", "");
        setMessage("");
        password.current = "";
        window.location.reload(false);
    }

    React.useEffect(() => {
        const token = window.localStorage.getItem("Token");
       
    }, [])


    const password = React.useRef();

    async function handleLogin(pass) {
          const h = {
            'Content-Type': 'application/json',
        }
        var options = {
            method: 'POST',
            mode: 'cors',
            headers: h,
            body: JSON.stringify({ "username": "john", "password": pass })
        }

        const response = await fetch(AuthEndPoint, options);
        const reply = await response.json();


        if (reply.statusCode === 401) {
            setMessage("Unauthorized");
            logout();
        } else {
            setMessage("ok!");
            window.localStorage.setItem("Token", reply.access_token);
        }


    
    }

    return (
        <>
            {window.localStorage.getItem("Token") === "" ?
        <CssVarsProvider>
            <main>
                <Sheet
                    sx={{
                        width: 300,
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & bottom
                        py: 3, // padding top & bottom
                        px: 2, // padding left & right
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <div>
                        <Typography level="h4" component="h1">
                        </Typography>
                        <Typography level="body2">Sign in to continue.</Typography>
                    </div>
                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input
                            // html input attribute
                            name="password"
                            type="password" 
                            placeholder="password"
                            onChange={(e) => password.current = e.target.value}
                        />
                    </FormControl>
                    <Button sx={{ mt: 1 /* margin top */ }} onClick={() => handleLogin(password.current)}>Log in</Button>
                </Sheet>
            </main>
            <div>{message}</div>
        </CssVarsProvider >
                :
                <>
                    <div className="MainW"><BuroCrat Admin={true} Token={window.localStorage.getItem("Token")} logout={logout} /></div>
                </>
        }
        </>
 
    );
}

//N266ZVE5AZATRDFV
