import { React ,useState, useRef, useEffect} from 'react';
import { isMobile } from 'react-device-detect';
import Header from './Header';
import { useNavigate  } from "react-router-dom";


function Register(props) {

    const registerEndPoint = process.env.REACT_APP_REGISTER_END_POINT;
    const confirmEndPoint = process.env.REACT_APP_CONFIRMDIGITS_END_POINT;

    const openInSameTab = url => {
        window.open(url, '_self', 'noopener,noreferrer');
    };

    const login = useRef();
    const password = useRef();
    const password2 = useRef();
    const confirm = useRef();
    
    const [message, setMessage] = useState("");
    const [stage, setStage] = useState(1);
    const [eml, setEml] = useState("");
    
    var waitIntervall;

    function showMessage(message, time = 5000) { 
        clearInterval(waitIntervall);
        setTimeout(() => {
            setMessage(""); 
            setMessage(message);
        }, 1000);
        setTimeout(() => {
            setMessage("");    
        }, time);
    }


    const SubmitRegister = async () => {
        
            if (password.current.value != password2.current.value) { 
                showMessage("passwords didn't match");
                return
            }


            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: login.current.value,password: password.current.value })
                };
                await fetch(registerEndPoint, requestOptions)
                    .then(response => response.json())
                    .then(function (data) {
                        console.log(data);
                        if (!data.token){
                            showMessage(Array.isArray(data.message) ? data.message[0] : data.message); 
                        } else { 
                            //showMessage("Welcome to hyperUranium, please check your inbox to confirm your email!");
                            setEml(login.current.value);
                           
                            setStage(stage + 1);
                        }
                   
                    } );
                
            } catch (e) { 
                console.log("erro");
                console.log(e);
            }


    }

    const SubmitConfirm = async () => {
        if (confirm.current.value.trim() === "" ) { 
            showMessage("Code cant be blank");
            return
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        await fetch(confirmEndPoint + "/" + eml + "/" + confirm.current.value, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if (data.message) { 
                    showMessage(data.message);
                } else {
                    sessionStorage.setItem("userinfo", JSON.stringify({ id: data.id, token: data.token }));
                    props.callback();
                    //setStage(stage + 1);
              
                       
                }
            });

    }
    useEffect(() => { 
        console.log("register Process")
        console.log(props);
    })

    return (
        <>
           <div className="loginMessage" >{message}</div>
            {stage === 1 ?
                <div className="login">
                    <form>
                        <input className="email" ref={login} placeholder="email" />
                        <br />
                        <input autoComplete='password' className="password" type="password" ref={password} placeholder="password" />
                        <br />
                        <input autoComplete='password' className="password" type="password" ref={password2} placeholder="retype password" />
                        <br />
                    </form>
                    <div className='LoginButtons'>
                        <br />
                        <div className={'mouse2 register btt'} onClick={(e) => SubmitRegister()} >[Register]</div>
                        <div className={'mouse2 register btt'} onClick={(e) => props?.alreadyToLogin ? openInSameTab("/login") : props.back(1)} >[ ALREADY REGISTERED ]</div>
                        
                    </div>
                </div>
                :
                stage === 2 ?
                <div className="login">
                    Please insert your 6 digit<br /> code that you receive on your email
                    <br /><br />
                    <input className="password" ref={confirm}  /><br />
                    <div className={'mouse2 register btt'} onClick={(e) => SubmitConfirm()} >[Confirm]</div>
                    <br />
                    didn't get the code? <div className='mouse2' onClick={(e) => null} >[Resend]</div>
                    </div>
                    :
                    <div className="login">
                        Welcome to hyperUranium!
                        <br />
                        you now can
                        <br />
                    <div className={"mouse2"} onClick={(e) => openInSameTab('/')} > [ EXPLORE THE GALAXY ] </div>
                    <div className={"mouse2"} onClick={(e) => openInSameTab('/listing')} > [ Explore the Listing ] </div>
                    
                    </div>    
                 }
           </>
        )
}

export default Register

