import { React, useState, useRef, useEffect } from 'react';
import Register from './register';

function LoginProcess(props) {

    const loginEndPoint = process.env.REACT_APP_LOGIN_END_POINT;
    const resetEndPoint = process.env.REACT_APP_RESET_END_POINT;
    const confirmEndPoint = process.env.REACT_APP_CONFIRMDIGITS_END_POINT;
    const changeEndPoint = process.env.REACT_APP_CHANGE_END_POINT;

 
    var waitIntervall;

    function showMessage(message, time = 5000) { 
        clearInterval(waitIntervall);
        setMessage("");
        setTimeout(() => {
            setMessage(message);    
        }, 100);
        setTimeout(() => {
            setMessage("");    
        }, time);
    }

    const password = useRef();
    const digits = useRef();
    const password1 = useRef();
    const password2 = useRef();
    
    const [message, setMessage] = useState("");
    const [stage, setStage] = useState(1);
    const [login, setLogin] = useState("");
    const [token, setToken] = useState("");
    const [reg, setReg] = useState(false);
    

    function pleaseWait() {
        setMessage("Please Wait");
    }


    async function ForgotPassword() { 
        pleaseWait();
        if (login=== "") { 
            showMessage("invalid email");
            return;
        }
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            await fetch(resetEndPoint + "/" + login , requestOptions)
                .then(response => response.json())
                .then(function (data) {
                    console.log(data);
                    if (data.message) { 
                        setStage(stage + 1);
                        setMessage("");    
                    }
                });


        } catch (e) { 
            console.log("erro");
            console.log(e);
        }

    }

    async function confirm() { 
        if (digits.current.value === "") { 
            showMessage("digits cannot be empty");
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        await fetch(confirmEndPoint + "/" + login + "/" + digits.current.value, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if (data.message) { 
                    showMessage(data.message);
                }else {
                    setToken(data.token);
                    setStage(stage + 1);
                }
            });
           
    }

    async function change() { 
        if (password1.current.value != password2.current.value || password1.current.value === "") { 
            showMessage("passwords didn't match");
            return
        }

        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password1.current.value,token: token })
            };
            await fetch(changeEndPoint, requestOptions)
                .then(response => response.json())
                .then(function (data) {
                    showMessage(data.message);
                    setTimeout(function () { 
                        setStage(1);
                        setLogin("");
                        setToken("");
                    }, 5000);
                });


        }catch (e) { 
            console.log("erro");
            console.log(e);
        }
    }


        const SubmitLogin = async () => {
  
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: login,password: password.current.value })
                };
                await fetch(loginEndPoint, requestOptions)
                    .then(response => response.json())
                    .then(function (data) {
                        console.log(data);
                        if (!data.token){
                            showMessage("Wrong Username or Password");
                         
                        } else { 
                            console.log(data);
                            showMessage("Login ok!");
                            sessionStorage.setItem("userinfo", JSON.stringify({ id: data.id, token: data.token }));
                            props.callback();
                          
                        }

                    } );
                
            } catch (e) { 
                console.log("erro");
                console.log(e);
            }
    }
    function register() { 
        setStage(4);
    }

    function Registercallback() { 
        props.callback();
    }

    useEffect(() => { 
        console.log("loginProcess");
        console.log(props);
    })

    return (
              

        <>
            <div className="loginMessage" >{message}</div>
            {stage === 1 ?  
                <form>
            <div className="login">
                        <input className="email" value={login} onChange={(e) => setLogin(e.target.value)}  placeholder="email" />
                <br / >
                <input autoComplete="password" className="password"  type="password"  ref={password} placeholder="password"  />
                <br />
                <div className='LoginButtons'>
                    <div className={'loginbt btt'} onClick={(e) => SubmitLogin()}>[Login]</div> 
                    <div className={'loginbt btt'} onClick={(e) => ForgotPassword()}>[Forgot Password]</div> 
                    <div className={'loginbt btt'} onClick={(e) => register()}>[Register]</div> 
                        </div>
                    </div>
                    </form>
                :
                stage === 2 ?
            <div className="login">
                    Please insert your 6 digit<br /> code that you receive on your email
                    <br /><br />
                    <input className="password" ref={digits} /><br />
                    <div className={'mouse2 register btt'} onClick={(e) => confirm()} >[Confirm]</div>
                    <br />
                    didn't get the code? <div className='mouse2' onClick={(e) => null} >[Resend]</div>
                    </div>
                    :
                    stage === 3 ?
                    <div className="login">
                        <form>
                        Insert your new password <br/><br/>
                        <input autoComplete='password' ref={password1} className="password" type="password"   placeholder="password" />
                        <br />
                            <input autoComplete='password' ref={password2} className="password" type="password"  placeholder="retype password" />
                        <br />
                        </form>
                        <br/>
                        <div className='LoginButtons'>
                            <div className={'loginbt btt'} onClick={(e) => change()}>[SAVE]</div> 
                        </div>
                        </div> :
                       <>
                            <Register setStage={setStage} callback={Registercallback} back={setStage}  />    
                        </>
            }
            </>
        
        )
}

export default LoginProcess

